import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabPanel } from '@mui/lab';
import { Fragment, useState } from 'react';
import {
  ReportDefinition,
  ReportFilter,
  ReportFilterInputType,
  ReportFilterOperator,
} from '../../models/report-details';
import { generateUUID } from '../../utils/common-utils';
import { CloseSharp } from '@mui/icons-material';
import { SelectedFilterBlock } from './filter-edit';
import { BaseQueryColumnInfo } from '../../models/report-info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReportButton } from '../dashboard/report-button';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderFilterItem, changeFilterSelection, addNewFilter } from './filters-tab-helper';

type PropsType = {
  setSelectedFilter: (column: ReportFilter) => void;
  selectedFilter: ReportFilter;
  setNewFilters: (column: ReportFilter[]) => void;
  newFilters: ReportFilter[];
  selectedReport: ReportDefinition;
  potentialColumns: BaseQueryColumnInfo[];
  setHasUnsavedChanges: (val: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  filterItemIcon: {
    display: 'none',
  },
  deleteFilterIcon: {
    '&:hover': {
      color: 'red',
    },
  },
  filterItemArrowIconUp: {
    position: 'absolute',
    top: '-0.15em',
    right: '0.25em',
    fontSize: '2em',
    '&:hover': {
      color: '#44e060',
    },
  },
  filterItemArrowIconDown: {
    position: 'absolute',
    bottom: '-0.15em',
    right: '0.25em',
    fontSize: '2em',
    '&:hover': {
      color: '#44e060',
    },
  },
  filterItem: {
    '&:hover > .MuiListItemIcon-root': {
      display: 'inline-block',
    },
  },
  selectedFilter: {
    background: '#f0f0f0',
  },
  newFilter: {
    background: '#feefc9',
  },
  formControl: {
    margin: '8px 16px 16px 16px',
    minWidth: '80%',
  },
  listArea: {
    background: '#fafafa',
    borderLeft: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    borderRadius: '4px',
    margin: '8px 16px 16px 16px',
  },
  scrollableList: {
    height: '340px',
    overflowY: 'scroll',
  },
}));

export const ReportFiltersTab: React.FC<PropsType> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    setSelectedFilter,
    selectedFilter,
    setNewFilters,
    newFilters,
    selectedReport,
    potentialColumns,
    setHasUnsavedChanges,
  } = props;

  const saveSelectedFilter = (changes: ReportFilter) => {
    const savedFilter = {
      ...selectedFilter,
      ...changes,
      sqlColumn: changes.selectColumn,
      selectColumn: changes.selectColumn,
      jsonParams: JSON.stringify({
        clusionType: changes.clusionType,
        clusionItems: changes.clusionItems,
        defaultValueMin: changes.defaultValueMin?.toString(),
        defaultValueMax: changes.defaultValueMax?.toString(),
        isHidden: changes.isHidden?.toString(),
      }),
    };

    if (
      !!newFilters &&
      newFilters.some((filter) => filter.uuid === savedFilter.uuid)
    ) {
      selectedReport.filterDefinitions.push(savedFilter);
      setNewFilters(
        newFilters.filter((filter) => filter.uuid !== savedFilter.uuid)
      );
    } else {
      selectedReport.filterDefinitions =
        selectedReport.filterDefinitions.filter(
          (x) => x.uuid != savedFilter.uuid
        );
      selectedReport.filterDefinitions.push(savedFilter);
    }
    changeFilterSelection(
      savedFilter,
      selectedIndex,
      setSelectedFilter,
      setSelectedIndex
    );
    setHasUnsavedChanges(true);
  };

  return (
    <TabPanel value='filters' tabIndex={2} style={{ padding: '0 0 16px 0' }}>
      <div style={{ display: 'flex', padding: '16px 0' }}>
        <ReportButton
          onClick={() =>
            addNewFilter(
              setNewFilters,
              newFilters,
              setSelectedFilter,
              setHasUnsavedChanges
            )
          }
          title={t('NEW_FILTER', 'NYTT FILTER')}></ReportButton>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', width: '33%', marginRight: '16px' }}>
          {selectedReport.filterDefinitions.length ? (
            <div style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  padding: '16px',
                  backgroundColor: '#fff',
                  marginBottom: '1px',
                  fontWeight: 'bold',
                }}>
                {t('FILTERS', 'Filters')}
              </div>
              {!!newFilters &&
                newFilters.map((filter, index) => (
                  <>
                    {renderFilterItem(
                      filter,
                      -(index + 1),
                      setSelectedIndex,
                      newFilters,
                      selectedFilter,
                      setSelectedFilter,
                      setNewFilters,
                      selectedReport,
                      setHasUnsavedChanges
                    )}
                  </>
                ))}
              {selectedReport.filterDefinitions.map((filter, index) => (
                <Fragment key={index}>
                  {renderFilterItem(
                    filter,
                    index,
                    setSelectedIndex,
                    newFilters,
                    selectedFilter,
                    setSelectedFilter,
                    setNewFilters,
                    selectedReport,
                    setHasUnsavedChanges
                  )}
                </Fragment>
              ))}
            </div>
          ) : null}
        </div>
        {!!selectedFilter && (
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              padding: '16px',
              width: '60%',
            }}>
            <SelectedFilterBlock
              initialValues={selectedFilter}
              index={selectedIndex}
              baseQuery={selectedReport.baseQueryCode}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              setNewFilters={setNewFilters}
              newFilters={newFilters}
              selectedReport={selectedReport}
              setHasUnsavedChanges={setHasUnsavedChanges}
              onSubmit={(x) => saveSelectedFilter(x)}
              potentialColumns={potentialColumns}
              baseQueryCode={selectedReport.baseQueryCode}
              open></SelectedFilterBlock>
          </div>
        )}
      </div>
    </TabPanel>
  );
};
