import { Card, CardActions, CardContent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReportButton } from '../dashboard/report-button';
import { dateTimeString } from '../../utils/common-utils';
import {
  ReportHistoryEvent,
  ReportHistoryEventType,
} from '../../models/report-history-event';
import MessageIcon from '@mui/icons-material/Message';
import ConfirmDialog from '../confirm-dialog';
import ReportConfigClient from '../../clients/ReportConfigClient';
import { useState } from 'react';
import { ReportGroup } from '../../models/report-details';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {},
  cardInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    '&.MuiCardContent-root': {
      padding: '0px',
    },
  },
}));
export const HistoryCard = ({
  event,
  setNewReport,
  selectedGroup,
  setSelectedReport,
  setSavingDeletingSub,
  handleDrawerClose,
}) => {
  const styles = useStyles();
  const [revertingItem, setRevertingItem] = useState(null);
  const [revertConfirmOpen, setRevertConfirmOpen] = useState(false);
  const { t } = useTranslation();  

  const getHistoryEventLabel = (status: ReportHistoryEventType) => {
    switch (status) {
      case ReportHistoryEventType.Created:
        return 'Created';
      case ReportHistoryEventType.Updated:
        return 'Updated';
      case ReportHistoryEventType.Reverted:
        return 'Reverted';
      case ReportHistoryEventType.Deleted:
        return 'Deleted';
    }
    return '';
  };
  const revertReport = () => {
    handleDrawerClose();
    if (!revertingItem) {

      return;
    }
    const sub = ReportConfigClient.revertReport(revertingItem.id).subscribe(
      (x) => {
        var reportIndex = selectedGroup.findIndex(
          (r) => r.code == x.code
        );
        if (reportIndex !== -1) {
          selectedGroup.reports[reportIndex] = x;
        } else {
          selectedGroup.push(x);
          setNewReport(null);
        }        
        setSelectedReport(x);
        setSavingDeletingSub(null);
      }
    );
  };
  return (
    <>
      <Card
        elevation={3}
        style={{
          width: '350px',
          padding: '12px',
          marginBottom: '6px',
          borderRadius: '0px',
          borderLeft: 'solid 6px #66bb6a',
        }}>
        <CardContent style={{ padding: '8px' }}>
          <div className={styles.cardInfo}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '6px' }}>
                <MessageIcon />
              </div>
              <div style={{ fontWeight: 'bold', marginRight: '12px' }}>
                {event.responsibleUserName}
              </div>
            </div>

            <div>{dateTimeString(new Date(event.timestamp))}</div>
          </div>

          <div style={{ fontWeight: 'bold' }}>
            {getHistoryEventLabel(event.eventType)}
          </div>
        </CardContent>
        <CardActions>
          <ReportButton
            onClick={() => {
              setRevertingItem(event);
              setRevertConfirmOpen(true);
            }}
            primary
            title={t(
              'RESET_TO_PREVIOUS_VERSION',
              'Tilbakestill til forrige versjon'
            )}
          />
        </CardActions>
      </Card>
      <ConfirmDialog
        // title={`Do you want to revert back to change #${revertingItem?.versionNumber}?`}
        title={t(
          'HISTORY_CHANGE_REVERT_DIALOG',
          'Do you want to revert back to change #{{versionNumber}}?',
          { versionNumber: revertingItem?.versionNumber }
        )}
        open={revertConfirmOpen}
        setOpen={setRevertConfirmOpen}
        onConfirm={revertReport}></ConfirmDialog>
    </>
  );
};
