/** @jsxImportSource @emotion/react */
import React from 'react';
import { FormControl, FormGroup, TextField } from '@mui/material';
import { InputType } from '../input-type.enum';
export type CubitTextFieldAdapterProps = { type: InputType.TextField }; // type props are required to differenciate between different field adapters

const CubitTextFieldAdapter: React.FC<any> = (props: any) => {
  const {
    width,
    input: { id, name, value, onChange, onFocus },
    options,
    formcontrolprops,
    meta: { touched, error },
    ...rest
  } = props;

  return (
    <FormControl sx={{ width: '100%' }} variant='standard'>
      <FormGroup row>
        <TextField
          variant='standard'
          id={id || name}
          error={touched && error ? true : false}
          helperText={touched && error}
          {...rest}
          required={false}
          name={name}
          onChange={(event: any) => onChange(event.target.value)}
          onFocus={onFocus}
          fullWidth
          {...props.input}
          value={value}
        />
      </FormGroup>
    </FormControl>
  );
};

export { CubitTextFieldAdapter };
