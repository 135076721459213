import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import ReportConfigClient from '../../clients/ReportConfigClient';
import { SaveReportGroup } from '../../models/report-details';
import { ReportGroupInfo } from '../../models/report-info';
import { AppState } from '../../redux/app-store';
import { ReportsActions } from '../../redux/reports-actions';
import pascalcase from '../../utils/pascal-case';
import { ReportButton } from './report-button';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { NewGroupSnackbar } from '../new-group-snackbar';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '80%',
    margin: '8px 0',
  },
  addIcon: {
    marginTop: '36px',
    marginLeft: '8px',
    width: '32px',
    height: '32px',
    color: '#44ef50',
    cursor: 'pointer',
  },
}));

export const ReportGroupsDialogV2: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const tenantConfigs = useSelector(
    (state: AppState) => state.reports.tenantConfigs
  );
  const userConfig = useSelector((state: AppState) => state.reports.userConfig);
  const [savedGroup, setSavedGroup] = useState<SaveReportGroup>({
    tenantIds: [],
    displayName: 'New group',
    code: pascalcase('New group'),
    categoryName: '',
    isGloballyVisible: userConfig?.isReportVisibilityManagementAllowed ?? false,
  });  
  const [busySub, setBusySub] = useState<Subscription>(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [createdGroup, setCreatedGroup] = useState<SaveReportGroup | null>(null); 
  const dispatch = useDispatch();

  const styles = useStyles();

  const forceUpdate = () => {
    return setUpdateCount((updateCount) => updateCount + 1);
  };

  const saveGroup = () => {
    const sub = ReportConfigClient.saveReportGroup(savedGroup).subscribe(
      (result) => {
        ReportsActions.actionLoadReportGroups()(dispatch);
        setOpen(false);
        setCreatedGroup(savedGroup); // Store the created group
        setSnackbarOpen(true); // Open the Snackbar
      }
    );
    setBusySub(sub);
    setSavedGroup({
      tenantIds: [],
      displayName: 'New group',
      code: pascalcase('New group'),
      categoryName: '',
      isGloballyVisible: userConfig?.isReportVisibilityManagementAllowed ?? false,
    })
  };

  return (
    <>
      <div style={{ marginRight: '12px' }}>
        <ReportButton
          onClick={() => setOpen(true)}
          primary
          startIcon={<AddIcon />}
          title={t('NEW_REPORT_GROUP', 'NY rapport Gruppe')}
        />
      </div>
      <Dialog
        open={open}
        maxWidth={'xs'}
        onClose={() => setOpen(false)}
        fullWidth
        aria-labelledby='new-reportgroup-dialog'>
        <DialogTitle id='new-reportgroup-dialog'>
          {t('ADD_NEW_GROUP', 'Lag ny rapportgruppe')}
        </DialogTitle>
        <DialogContent>
          {!!savedGroup && !busySub && (
            <>
              <TextField
                variant='standard'
                className={styles.formControl}
                label={t('TITLE', 'Tittel')}
                value={savedGroup.displayName}
                onChange={(ev) => {
                  savedGroup.displayName = ev.target.value as string;

                  if (!savedGroup.id) {
                    savedGroup.code = pascalcase(savedGroup.displayName);
                  }
                  forceUpdate();
                }}></TextField>
              {/*<TextField*/}
              {/*  variant="standard"*/}
              {/*  label='Category name'*/}
              {/*  className={styles.formControl}*/}
              {/*  value={savedGroup.categoryName}*/}
              {/*  onChange={(ev) => {*/}
              {/*    savedGroup.categoryName = ev.target.value as string;*/}
              {/*    forceUpdate();*/}
              {/*  }}></TextField>*/}
              {userConfig?.isReportVisibilityManagementAllowed && (
                <div style={{ marginTop: 30 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={savedGroup.isGloballyVisible}
                        onChange={(ev) => {
                          savedGroup.isGloballyVisible = ev.target.checked;
                          forceUpdate();
                        }}
                      />
                    }
                    label={t('IS_GLOBALLY_VISIBLE', 'Er globalt synlig')}
                  />
                  {!savedGroup.isGloballyVisible && (
                    <div style={{ margin: '10px 0' }}>
                      <Autocomplete
                        filterSelectedOptions
                        id='combo-box-demo'
                        options={tenantConfigs.map((tenant, index) => {
                          return {
                            label: tenant.tenantName,
                            id: tenant.tenantId,
                          };
                        })}
                        fullWidth={true}
                        multiple
                        onChange={(event, value) => {
                          savedGroup.tenantIds = value.map((v) => v.id);

                          forceUpdate();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant={'standard'}
                            label={t('SEARCH_TENANT', 'Search tenant...')}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {!!busySub && (
            <div className='p-3'>
              <TailSpin
                wrapperStyle={{ display: 'inline-block' }}
                color='#385690'
                height={32}
                width={32}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={() => setOpen(false)}>
            {t('CANCEL', 'Avbryt')}
          </Button>
          <Button variant='contained' onClick={saveGroup} color='primary'>
            {t('SAVE', 'Lagre')}
          </Button>
        </DialogActions>
      </Dialog>
      <NewGroupSnackbar setBusyGroupSub={()=>setBusySub(null)} isOpen={snackbarOpen} group={createdGroup} handleClose={() => setSnackbarOpen(false)}/>
    </>
  );
};
