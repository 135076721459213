import { useTranslation } from 'react-i18next';
import { FilterObject } from '../../models/filter-object';
import {
  ReportFilterInfo,
  ReportFilterOption,
} from '../../models/report-filter';
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    display: 'flex',
    padding: '10px 0',
    backgroundColor: '#fff'
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
  },
  checkboxLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3f558c',
    },
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
export const MultiSelectFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  const { filter, pendingFilters, setPendingFilters } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  const onChange = (code: string, checked: boolean) => {
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId == filter.filterId
    );

    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }

    if (checked && !filterItem.values.includes(code)) {
      filterItem.values.push(code);
    } else if (!checked && filterItem.values.includes(code)) {
      filterItem.values.splice(filterItem.values.indexOf(code), 1);
    }
    setPendingFilters({ ...pendingFilters });
  };

  const isChecked = (option: ReportFilterOption): boolean => {
    return !!filterItem && filterItem.values.includes(option.code);
  };

  if (filter.isHidden) {
    return <span></span>;
  }

  var filterItem = pendingFilters.appliedFilters.find(
    (x) => x.filterId == filter.filterId
  );

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupLabel}>{filter.displayName}</div>
      <div className={styles.options}>
        {filter.options.map((option) => (
          <FormControlLabel
            className={styles.checkboxLabel}
            key={option.value}
            control={
              <Checkbox
                className={styles.checkbox}
                checked={isChecked(option)}
                onChange={(event) =>
                  onChange(option.code, event.target.checked)
                }
                value={option.value}
              />
            }
            label={option.value}
          />
        ))}
      </div>
    </div>
  );
};
