import { BaseQueryColumnInfo } from '../../models/report-info';
import { TFunction } from 'i18next';


export const processedColumns = (
  baseQuery: string,
  potentialColumns: BaseQueryColumnInfo[],
  t: TFunction
) => {
  const auditsGroups = [
    { name: 'Building', label: t('BUILDING', 'Building') },
    { name: 'Chimney', label: t('CHIMNEY', 'Chimney') },
    { name: 'Document', label: t('DOCUMENT', 'Document') },
    { name: 'Error', label: t('ERROR', 'Error') },
    { name: 'HousingUnit', label: t('HOUSING_UNIT', 'Housing unit') },
    { name: 'Room', label: t('ROOM', 'Room') },
    { name: 'Audit', label: t('AUDIT', 'Audit') },
  ];
  const buildingsGroups = [
    { name: 'Building', label: t('BUILDING', 'Building') },
    { name: 'Chimney', label: t('CHIMNEY', 'Chimney') },
    { name: 'Fireplace', label: t('FIREPLACE', 'Fireplace') },
    { name: 'HousingUnit', label: t('HOUSING_UNIT', 'Housing unit') },
    { name: 'MatrikkelUnit', label: t('CADASTRE_UNIT', 'Cadastre unit') },
  ];
  const defaultGroup = { name: 'Columns', label: t('COLUMNS', 'Columns') };
  console.log(baseQuery);

  const processedColumns = potentialColumns.map((column) => {
    const words = column.name.split(' ');
    const defaultAuditGroup = { name: 'Audit', label: t('AUDIT', 'Audit') };
    if (baseQuery === 'FireAudits') {
      const { name: auditsGroupName, label: auditsGroupLabel } =
        words.length > 1
          ? auditsGroups.find(
              (group) =>
                group.name.toLowerCase() === words[1].trim().toLowerCase()
            ) || defaultAuditGroup
          : defaultAuditGroup;
      return {
        ...column,
        group: auditsGroupName,
        groupLabel: auditsGroupLabel,
      };
    } else if (baseQuery === 'FireBuildings') {
      const { name: buildingsGroupName, label: buildingsGroupLabel } =
        words.length > 1
          ? buildingsGroups.find(
              (group) =>
                group.name.toLowerCase() === words[0].trim().toLowerCase()
            ) || defaultGroup
          : defaultGroup;
      return {
        ...column,
        group: buildingsGroupName,
        groupLabel: buildingsGroupLabel,
      };
    } else {
      return {
        ...column,
        group: defaultGroup,
        groupLabel: t('COLUMNS', 'Columns'),
      };
    }
  });
  const sortedProcessedColumns = processedColumns.sort((a, b) =>
    a.groupLabel.localeCompare(b.groupLabel)
  );
  return sortedProcessedColumns;
};

export const ColumnText = (t: TFunction) => {
  return (text: string) => {
    const id = text?.replaceAll(' ', '_').toLowerCase();
    if (!id) return text;
    // FireBuildings base query:
    // t('field_housingunit_id', 'HousingUnit Id')
    // t('field_housingunit_lastcontroldate', 'HousingUnit LastControlDate')
    // t('field_housingunit_nextcontroldate', 'HousingUnit NextControlDate')
    // t('field_housingunit_address', 'HousingUnit Address')
    // t('field_housingunit_postalcode', 'HousingUnit PostalCode')
    // t('field_housingunit_type', 'HousingUnit Type')
    // t('field_housingunit_usablearea', 'HousingUnit UsableArea')
    // t('field_housingunit_inspectionrate', 'HousingUnit InspectionRate')
    // t('field_housingunit_riskassesment', 'HousingUnit RiskAssesment')
    // t('field_housingunit_attribute', 'HousingUnit Attribute')
    // t('field_housingunit_longitude', 'HousingUnit Longitude')
    // t('field_housingunit_latitude', 'HousingUnit Latitude')
    // t('field_housingunit_inspectionratesetby', 'HousingUnit InspectionRateSetBy')
    // t('field_housingunit_nextcontrolsetby', 'HousingUnit NextControlSetBy')
    // t('field_housingunit_district', 'HousingUnit District')
    // t('field_housingunit_residentialnumber', 'HousingUnit ResidentialNumber')
    // t('field_housingunit_basicstatisticalunitname', 'HousingUnit BasicStatisticalUnitName')
    // t('field_housingunit_basicstatisticalunitnumber', 'HousingUnit BasicStatisticalUnitNumber')
    // t('field_housingunit_full_route', 'HousingUnit Full Route')
    // t('field_housingunit_route1_name', 'HousingUnit Route1 Name')
    // t('field_housingunit_route2_name', 'HousingUnit Route2 Name')
    // t('field_housingunit_route3_name', 'HousingUnit Route3 Name')
    // t('field_housingunit_route4_name', 'HousingUnit Route4 Name')
    // t('field_housingunit_route1_number', 'HousingUnit Route1 Number')
    // t('field_housingunit_route2_number', 'HousingUnit Route2 Number')
    // t('field_housingunit_route3_number', 'HousingUnit Route3 Number')
    // t('field_housingunit_route4_number', 'HousingUnit Route4 Number')
    // t('field_fireplace_id', 'Fireplace Id')
    // t('field_fireplace_fueltype', 'Fireplace FuelType')
    // t('field_fireplace_description', 'Fireplace Description')
    // t('field_fireplace_installeddate', 'Fireplace InstalledDate')
    // t('field_fireplace_installedby', 'Fireplace InstalledBy')
    // t('field_fireplace_approveddate', 'Fireplace ApprovedDate')
    // t('field_fireplace_isinuse', 'Fireplace IsInUse')
    // t('field_fireplace_type', 'Fireplace Type')
    // t('field_fireplace_location', 'Fireplace Location')
    // t('field_fireplace_sintefnumber', 'Fireplace SintefNumber')
    // t('field_fireplace_producer', 'Fireplace Producer')
    // t('field_fireplace_productname', 'Fireplace ProductName')
    // t('field_fireplace_producttype', 'Fireplace ProductType')
    // t('field_fireplace_cleanburning', 'Fireplace CleanBurning')
    // t('field_fireplace_iscomplicated', 'Fireplace IsComplicated')
    // t('field_fireplace_connectionpipetype', 'Fireplace ConnectionPipeType')
    // t('field_chimney_id', 'Chimney Id')
    // t('field_chimney_number', 'Chimney Number')
    // t('field_chimney_dimension', 'Chimney Dimension')
    // t('field_chimney_type', 'Chimney Type')
    // t('field_chimney_soothatch', 'Chimney SootHatch')
    // t('field_chimney_sootqty', 'Chimney SootQty')
    // t('field_chimney_cleaningdoor', 'Chimney CleaningDoor')
    // t('field_chimney_sintefnumber', 'Chimney SintefNumber')
    // t('field_chimney_producer', 'Chimney Producer')
    // t('field_chimney_productname', 'Chimney ProductName')
    // t('field_chimney_meterlength', 'Chimney MeterLength')
    // t('field_chimney_isrehabilitated', 'Chimney IsRehabilitated')
    // t('field_chimney_isinuse', 'Chimney IsInUse')
    // t('field_chimney_registrationdate', 'Chimney RegistrationDate')
    // t('field_chimney_lastsweepdate', 'Chimney LastSweepDate')
    // t('field_chimney_nextsweepdate', 'Chimney NextSweepDate')
    // t('field_chimney_location', 'Chimney Location')
    // t('field_chimney_riskassessment', 'Chimney RiskAssessment')
    // t('field_chimney_inspectionrate', 'Chimney InspectionRate')
    // t('field_chimney_attribute', 'Chimney Attribute')
    // t('field_building_id', 'Building Id')
    // t('field_building_name', 'Building Name')
    // t('field_building_type', 'Building Type')
    // t('field_building_code', 'Building Code')
    // t('field_building_custom', 'Building Custom')
    // t('field_building_number', 'Building Number')
    // t('field_building_status', 'Building Status')
    // t('field_building_builddate', 'Building BuildDate')
    // t('field_building_industrialtype', 'Building IndustrialType')
    // t('field_building_lastcontroldate', 'Building LastControlDate')
    // t('field_building_nextcontroldate', 'Building NextControlDate')
    // t('field_building_riskassessment', 'Building RiskAssessment')
    // t('field_building_inspectionrate', 'Building InspectionRate')
    // t('field_building_longitude', 'Building Longitude')
    // t('field_building_latitude', 'Building Latitude')
    // t('field_building_coordinatestype', 'Building CoordinatesType')
    // t('field_building_attribute', 'Building Attribute')
    // t('field_building_is1890building', 'Building Is1890Building')
    // t('field_building_indensearea', 'Building InDenseArea')
    // t('field_building_densewoodenarea', 'Building DenseWoodenArea')
    // t('field_building_distancetofirestation', 'Building DistanceToFirestation')
    // t('field_building_specificfireobject', 'Building SpecificFireObject')
    // t('field_matrikkelunit_id', 'MatrikkelUnit Id')
    // t('field_matrikkelunit_number', 'MatrikkelUnit Number')
    // t('field_matrikkelunit_latitude', 'MatrikkelUnit Latitude')
    // t('field_matrikkelunit_longitude', 'MatrikkelUnit Longitude')
    // t('field_matrikkelunit_owners', 'MatrikkelUnit Owners')
    // t('field_matrikkelunit_municipalityname', 'MatrikkelUnit MunicipalityName')
    // t('field_matrikkelunit_municipalitynumber', 'MatrikkelUnit MunicipalityNumber')
    // Fire audits base query:
    // t('field_id', 'Id')
    // t('field_additionaltype', 'AdditionalType')
    // t('field_casenumber', 'CaseNumber')
    // t('field_comment', 'Comment')
    // t('field_confirmationstatus', 'ConfirmationStatus')
    // t('field_createddate', 'CreatedDate')
    // t('field_createdmonth', 'CreatedMonth')
    // t('field_createdweek', 'CreatedWeek')
    // t('field_createdyear', 'CreatedYear')
    // t('field_deadlinedate', 'DeadlineDate')
    // t('field_deadlinemonth', 'DeadlineMonth')
    // t('field_deadlineweek', 'DeadlineWeek')
    // t('field_deadlineyear', 'DeadlineYear')
    // t('field_directresolution', 'DirectResolution')
    // t('field_hasauditedstate', 'HasAuditedState')
    // t('field_inspectorassigee', 'InspectorAssigee')
    // t('field_inspectorname', 'InspectorName')
    // t('field_inspectorsksname', 'InspectorSKSName')
    // t('field_inspectorsksorgnumber', 'InspectorSKSOrgNumber')
    // t('field_minutesduration', 'MinutesDuration')
    // t('field_planneddate', 'PlannedDate')
    // t('field_plannedmonth', 'PlannedMonth')
    // t('field_plannedweek', 'PlannedWeek')
    // t('field_plannedyear', 'PlannedYear')
    // t('field_remarks', 'Remarks')
    // t('field_resolutionby', 'ResolutionBy')
    // t('field_resolutiondate', 'ResolutionDate')
    // t('field_resolutionmonth', 'ResolutionMonth')
    // t('field_resolutionweek', 'ResolutionWeek')
    // t('field_resolutionyear', 'ResolutionYear')
    // t('field_starteddate', 'StartedDate')
    // t('field_startedmonth', 'StartedMonth')
    // t('field_startedweek', 'StartedWeek')
    // t('field_startedyear', 'StartedYear')
    // t('field_status', 'Status')
    // t('field_type', 'Type')
    // t('field_applicationform', 'ApplicationForm')
    // t('field_audit_tag', 'Audit Tag')
    // t('field_audit_document_type', 'Audit Document Type')
    // t('field_audit_document_senddate', 'Audit Document SendDate')
    // t('field_audit_document_sendyear', 'Audit Document SendYear')
    // t('field_audit_document_sendweek', 'Audit Document SendWeek')
    // t('field_audit_document_sendmonth', 'Audit Document SendMonth')
    // t('field_audit_error_code', 'Audit Error Code')
    // t('field_audit_error_comment', 'Audit Error Comment')
    // t('field_audit_error_paragraph', 'Audit Error Paragraph')
    // t('field_audit_error_law', 'Audit Error Law')
    // t('field_audit_error_regulation', 'Audit Error Regulation')
    // t('field_audit_room_name', 'Audit Room Name')
    // t('field_audit_room_suitablesweepaccess', 'Audit Room SuitableSweepAccess')
    // t('field_audit_room_sweeprequired', 'Audit Room SweepRequired')
    // t('field_audit_room_soothatchemptied', 'Audit Room SootHatchEmptied')
    // t('field_audit_room_chimneysootqty', 'Audit Room ChimneySootQty')
    // t('field_audit_room_detectionsystemhasstoveguard', 'Audit Room DetectionSystemHasStoveGuard')
    // t('field_audit_room_soothatchsootqty', 'Audit Room SootHatchSootQty')
    // t('field_audit_chimney_id', 'Audit Chimney Id')
    // t('field_audit_chimney_number', 'Audit Chimney Number')
    // t('field_audit_chimney_dimension', 'Audit Chimney Dimension')
    // t('field_audit_chimney_verified', 'Audit Chimney Verified')
    // t('field_audit_chimney_type', 'Audit Chimney Type')
    // t('field_audit_chimney_soothatch', 'Audit Chimney SootHatch')
    // t('field_audit_chimney_sootqty', 'Audit Chimney SootQty')
    // t('field_audit_chimney_cleaningdoor', 'Audit Chimney CleaningDoor')
    // t('field_audit_chimney_sintefnumber', 'Audit Chimney SintefNumber')
    // t('field_audit_chimney_producer', 'Audit Chimney Producer')
    // t('field_audit_chimney_productname', 'Audit Chimney ProductName')
    // t('field_audit_chimney_meterlength', 'Audit Chimney MeterLength')
    // t('field_audit_chimney_isrehabilitated', 'Audit Chimney IsRehabilitated')
    // t('field_audit_chimney_isinuse', 'Audit Chimney IsInUse')
    // t('field_audit_chimney_lastsweep', 'Audit Chimney LastSweep')
    // t('field_audit_chimney_nextsweep', 'Audit Chimney NextSweep')
    // t('field_audit_chimney_description', 'Audit Chimney Description')
    // t('field_audit_chimney_inspectionrate', 'Audit Chimney InspectionRate')
    // t('field_audit_chimney_location', 'Audit Chimney Location')
    // t('field_audit_chimney_riskassessment', 'Audit Chimney RiskAssessment')
    // t('field_audit_chimney_isswept', 'Audit Chimney IsSwept')
    // t('field_audit_chimney_sweepactivity', 'Audit Chimney SweepActivity')
    // t('field_audit_chimney_attribute', 'Audit Chimney Attribute')
    // t('field_audit_specificfireobject_id', 'Audit SpecificFireObject Id')
    // t('field_audit_housingunit_id', 'Audit HousingUnit Id')
    // t('field_audit_housingunit_municipalitynumber', 'Audit HousingUnit MunicipalityNumber')
    // t('field_audit_housingunit_municipalityname', 'Audit HousingUnit MunicipalityName')
    // t('field_audit_housingunit_type', 'Audit HousingUnit Type')
    // t('field_audit_housingunit_usablearea', 'Audit HousingUnit UsableArea')
    // t('field_audit_housingunit_inspectionrate', 'Audit HousingUnit InspectionRate')
    // t('field_audit_housingunit_inspectionratesetby', 'Audit HousingUnit InspectionRateSetBy')
    // t('field_audit_housingunit_riskassesment', 'Audit HousingUnit RiskAssesment')
    // t('field_audit_housingunit_address', 'Audit HousingUnit Address')
    // t('field_audit_housingunit_postalcode', 'Audit HousingUnit PostalCode')
    // t('field_audit_housingunit_district', 'Audit HousingUnit District')
    // t('field_audit_housingunit_residentialnumber', 'Audit HousingUnit ResidentialNumber')
    // t('field_audit_housingunit_heatingsourceattribute', 'Audit HousingUnit HeatingSourceAttribute')
    // t('field_audit_housingunit_attribute', 'Audit HousingUnit Attribute')
    // t('field_audit_housingunit_fireplace_id', 'Audit HousingUnit Fireplace Id')
    // t('field_audit_housingunit_fireplace_fueltype', 'Audit HousingUnit Fireplace FuelType')
    // t('field_audit_housingunit_fireplace_installeddate', 'Audit HousingUnit Fireplace InstalledDate')
    // t('field_audit_housingunit_fireplace_installedby', 'Audit HousingUnit Fireplace InstalledBy')
    // t('field_audit_housingunit_fireplace_approveddate', 'Audit HousingUnit Fireplace ApprovedDate')
    // t('field_audit_housingunit_fireplace_isinuse', 'Audit HousingUnit Fireplace IsInUse')
    // t('field_audit_housingunit_fireplace_type', 'Audit HousingUnit Fireplace Type')
    // t('field_audit_housingunit_fireplace_description', 'Audit HousingUnit Fireplace Description')
    // t('field_audit_housingunit_fireplace_location', 'Audit HousingUnit Fireplace Location')
    // t('field_audit_housingunit_fireplace_sintefnumber', 'Audit HousingUnit Fireplace SintefNumber')
    // t('field_audit_housingunit_fireplace_producer', 'Audit HousingUnit Fireplace Producer')
    // t('field_audit_housingunit_fireplace_productname', 'Audit HousingUnit Fireplace ProductName')
    // t('field_audit_housingunit_fireplace_producttype', 'Audit HousingUnit Fireplace ProductType')
    // t('field_audit_housingunit_fireplace_cleanburning', 'Audit HousingUnit Fireplace CleanBurning')
    // t('field_audit_housingunit_fireplace_iscomplicated', 'Audit HousingUnit Fireplace IsComplicated')
    // t('field_audit_housingunit_fireplace_connectionpipetype', 'Audit HousingUnit Fireplace ConnectionPipeType')
    // t('field_audit_building_id', 'Audit Building Id')
    // t('field_audit_building_number', 'Audit Building Number')
    // t('field_audit_building_name', 'Audit Building Name')
    // t('field_audit_building_serialnumber', 'Audit Building SerialNumber')
    // t('field_audit_building_code', 'Audit Building Code')
    // t('field_audit_building_type', 'Audit Building Type')
    // t('field_audit_building_builddate', 'Audit Building BuildDate')
    // t('field_audit_building_industrialtype', 'Audit Building IndustrialType')
    // t('field_audit_building_riskassessmentscore', 'Audit Building RiskAssessmentScore')
    // t('field_audit_building_standardfactor', 'Audit Building StandardFactor')
    // t('field_audit_building_comment', 'Audit Building Comment')
    // t('field_audit_building_specificfireobjectname', 'Audit Building SpecificFireObjectName')
    // t('field_audit_building_is1890building', 'Audit Building Is1890Building')
    // t('field_audit_building_indensearea', 'Audit Building InDenseArea')
    // t('field_audit_building_densewoodenarea', 'Audit Building DenseWoodenArea')
    // t('field_matrikkelunit_id', 'MatrikkelUnit Id')
    // t('field_matrikkelunit_number', 'MatrikkelUnit Number')
    // t('field_matrikkelunit_latitude', 'MatrikkelUnit Latitude')
    // t('field_matrikkelunit_longitude', 'MatrikkelUnit Longitude')
    // t('field_matrikkelunit_owners', 'MatrikkelUnit Owners')
    // El audits base query:
    // t('field_id', 'Id')
    // t('field_additionaltype', 'AdditionalType')
    // t('field_casenumber', 'CaseNumber')
    // t('field_comment', 'Comment')
    // t('field_confirmationstatus', 'ConfirmationStatus')
    // t('field_createddate', 'CreatedDate')
    // t('field_createdmonth', 'CreatedMonth')
    // t('field_createdweek', 'CreatedWeek')
    // t('field_createdyear', 'CreatedYear')
    // t('field_deadlinedate', 'DeadlineDate')
    // t('field_deadlinemonth', 'DeadlineMonth')
    // t('field_deadlineweek', 'DeadlineWeek')
    // t('field_deadlineyear', 'DeadlineYear')
    // t('field_directresolution', 'DirectResolution')
    // t('field_hasauditedstate', 'HasAuditedState')
    // t('field_inspectorassigee', 'InspectorAssigee')
    // t('field_inspectorname', 'InspectorName')
    // t('field_inspectorsksname', 'InspectorSKSName')
    // t('field_inspectorsksorgnumber', 'InspectorSKSOrgNumber')
    // t('field_invoice', 'Invoice')
    // t('field_minutesduration', 'MinutesDuration')
    // t('field_planneddate', 'PlannedDate')
    // t('field_plannedmonth', 'PlannedMonth')
    // t('field_plannedweek', 'PlannedWeek')
    // t('field_plannedyear', 'PlannedYear')
    // t('field_remarks', 'Remarks')
    // t('field_resolutionby', 'ResolutionBy')
    // t('field_resolutiondate', 'ResolutionDate')
    // t('field_resolutionmonth', 'ResolutionMonth')
    // t('field_resolutionweek', 'ResolutionWeek')
    // t('field_resolutionyear', 'ResolutionYear')
    // t('field_starteddate', 'StartedDate')
    // t('field_startedmonth', 'StartedMonth')
    // t('field_startedweek', 'StartedWeek')
    // t('field_startedyear', 'StartedYear')
    // t('field_status', 'Status')
    // t('field_transferredtosambas', 'TransferredToSambas')
    // t('field_type', 'Type')
    // t('field_audit_document_type', 'Audit Document Type')
    // t('field_audit_document_senddate', 'Audit Document SendDate')
    // t('field_audit_document_sendyear', 'Audit Document SendYear')
    // t('field_audit_document_sendweek', 'Audit Document SendWeek')
    // t('field_audit_document_sendmonth', 'Audit Document SendMonth')
    // t('field_audit_error_code', 'Audit Error Code')
    // t('field_audit_error_comment', 'Audit Error Comment')
    // t('field_audit_error_paragraph', 'Audit Error Paragraph')
    // t('field_audit_error_law', 'Audit Error Law')
    // t('field_audit_error_regulation', 'Audit Error Regulation')
    // t('field_audit_error_disconnection', 'Audit Error Disconnection')
    // t('field_audit_plant_id', 'Audit Plant Id')
    // t('field_audit_plant_meteringpointid', 'Audit Plant MeteringPointId')
    // t('field_audit_plant_consumptioncode', 'Audit Plant ConsumptionCode')
    // t('field_audit_plant_industrialclassification', 'Audit Plant IndustrialClassification')
    // t('field_audit_plant_buildingcode', 'Audit Plant BuildingCode')
    // t('field_audit_plant_connecteddate', 'Audit Plant ConnectedDate')
    // t('field_audit_plant_type', 'Audit Plant Type')
    // t('field_audit_plant_inspectionarea', 'Audit Plant InspectionArea')
    // t('field_audit_plant_riskcategorygroup', 'Audit Plant RiskCategoryGroup')
    // t('field_audit_plant_description', 'Audit Plant Description')
    // t('field_audit_plant_metermodel', 'Audit Plant MeterModel')
    // t('field_audit_plant_metersupplier', 'Audit Plant MeterSupplier')
    // t('field_audit_plant_meternumber', 'Audit Plant MeterNumber')
    // t('field_audit_plant_maincircuitbreakercurrent', 'Audit Plant MainCircuitBreakerCurrent')
    // t('field_audit_plant_maincircuitbreakerphase', 'Audit Plant MainCircuitBreakerPhase')
    // t('field_audit_plant_maincircuitbreakeramps', 'Audit Plant MainCircuitBreakerAmps')
    // t('field_audit_plant_i0', 'Audit Plant I0')
    // t('field_audit_plant_address', 'Audit Plant Address')
    // t('field_audit_plant_floor', 'Audit Plant Floor')
    // t('field_audit_plant_latitude', 'Audit Plant Latitude')
    // t('field_audit_plant_longitude', 'Audit Plant Longitude')
    // t('field_audit_plant_matrikkelnumber', 'Audit Plant MatrikkelNumber')
    // t('field_audit_plant_postalcode', 'Audit Plant PostalCode')
    // t('field_audit_plant_zonenumber', 'Audit Plant ZoneNumber')
    // t('field_audit_plant_zonename', 'Audit Plant ZoneName')
    // t('field_audit_plant_inspectionrate', 'Audit Plant InspectionRate')
    // t('field_audit_plant_tag', 'Audit Plant Tag')
    // t('field_audit_company_name', 'Audit Company Name')
    // t('field_audit_company_type', 'Audit Company Type')
    // t('field_audit_company_typeName', 'Audit Company Type Name')
    // t('field_audit_company_organizationnumber', 'Audit Company OrganizationNumber')
    // t('field_audit_company_parentorganizationnumber', 'Audit Company ParentOrganizationNumber')
    // t('field_audit_company_phone', 'Audit Company Phone')
    // t('field_audit_company_email', 'Audit Company Email')
    // t('field_audit_company_registrationdate', 'Audit Company RegistrationDate')
    // t('field_audit_company_numberofemployees', 'Audit Company NumberOfEmployees')
    // t('field_audit_company_isinvoluntaryregistry', 'Audit Company IsInVoluntaryRegistry')
    // t('field_audit_company_isinvatregistry', 'Audit Company IsInVatRegistry')
    // t('field_audit_company_isinbusinessregistry', 'Audit Company IsInBusinessRegistry')
    // t('field_audit_company_isinfoundationregistry', 'Audit Company IsInFoundationRegistry')
    // t('field_audit_company_elcompanyid', 'Audit Company ElCompanyId')
    // t('field_audit_company_elcompanystatus', 'Audit Company ElCompanyStatus')
    // t('field_audit_company_numberofelectriciansfrom', 'Audit Company NumberOfElectriciansFrom')
    // t('field_audit_company_numberofelectriciansto', 'Audit Company NumberOfElectriciansTo')
    // El plants base query:
    // t('field_plant_id', 'Plant Id')
    // t('field_plant_meteringpointid', 'Plant MeteringPointId')
    // t('field_plant_consumptioncode', 'Plant ConsumptionCode')
    // t('field_plant_industrialclassification', 'Plant IndustrialClassification')
    // t('field_plant_buildingcode', 'Plant BuildingCode')
    // t('field_plant_connecteddate', 'Plant ConnectedDate')
    // t('field_plant_type', 'Plant Type')
    // t('field_plant_inspectionarea', 'Plant InspectionArea')
    // t('field_plant_lastcontrol', 'Plant LastControl')
    // t('field_plant_nextcontrol', 'Plant NextControl')
    // t('field_plant_inspectionrate', 'Plant InspectionRate')
    // t('field_plant_riskcategorygroup', 'Plant RiskCategoryGroup')
    // t('field_plant_longitude', 'Plant Longitude')
    // t('field_plant_latitude', 'Plant Latitude')
    // t('field_plant_address', 'Plant Address')
    // t('field_plant_postalcode', 'Plant PostalCode')
    // t('field_plant_floor', 'Plant Floor')
    // t('field_plant_matrikkelnumber', 'Plant MatrikkelNumber')
    // t('field_plant_zonenumber', 'Plant ZoneNumber')
    // t('field_plant_zonename', 'Plant ZoneName')
    // t('field_plant_meternumber', 'Plant MeterNumber')
    // t('field_plant_metersupplier', 'Plant MeterSupplier')
    // t('field_plant_metermodel', 'Plant MeterModel')
    // t('field_plant_maincircuitbreakercurrent', 'Plant MainCircuitBreakerCurrent')
    // t('field_plant_maincircuitbreakerphase', 'Plant MainCircuitBreakerPhase')
    // t('field_plant_maincircuitbreakeramps', 'Plant MainCircuitBreakerAmps')
    // t('field_plant_i0', 'Plant I0')
    // t('field_plant_status', 'Plant Status')
    // t('field_plant_riskassessment', 'Plant RiskAssessment')
    // t('field_plant_description', 'Plant Description')
    // t('field_plant_externalnumber', 'Plant ExternalNumber')
    // t('field_plant_tag', 'Plant Tag')
    // t('field_plant_attr', 'Plant Attr')
    // t('field_customer_id', 'Customer Id')
    // t('field_customer_number', 'Customer Number')
    // t('field_customer_firstname', 'Customer FirstName')
    // t('field_customer_lastname', 'Customer LastName')
    // t('field_customer_phone', 'Customer Phone')
    // t('field_customer_organizationnumber', 'Customer OrganizationNumber')
    // t('field_customer_status', 'Customer Status')
    // t('field_connectionpoint_id', 'ConnectionPoint Id')
    // t('field_connectionpoint_name', 'ConnectionPoint Name')
    // t('field_connectionpoint_description', 'ConnectionPoint Description')
    // t('field_transformer_id', 'Transformer Id')
    // t('field_transformer_name', 'Transformer Name')
    // t('field_transformer_description', 'Transformer Description')
    // t('field_netstation_id', 'NetStation Id')
    // t('field_netstation_name', 'NetStation Name')
    // t('field_netstation_description', 'NetStation Description')
    // HC Audits base query:
    // t('field_id', 'Id')
    // t('field_additionaltype', 'AdditionalType')
    // t('field_casenumber', 'CaseNumber')
    // t('field_comment', 'Comment')
    // t('field_confirmationstatus', 'ConfirmationStatus')
    // t('field_createddate', 'CreatedDate')
    // t('field_createdmonth', 'CreatedMonth')
    // t('field_createdweek', 'CreatedWeek')
    // t('field_createdyear', 'CreatedYear')
    // t('field_deadlinedate', 'DeadlineDate')
    // t('field_deadlinemonth', 'DeadlineMonth')
    // t('field_deadlineweek', 'DeadlineWeek')
    // t('field_deadlineyear', 'DeadlineYear')
    // t('field_directresolution', 'DirectResolution')
    // t('field_hasauditedstate', 'HasAuditedState')
    // t('field_inspectorassigee', 'InspectorAssigee')
    // t('field_inspectorname', 'InspectorName')
    // t('field_inspectorsksname', 'InspectorSKSName')
    // t('field_inspectorsksorgnumber', 'InspectorSKSOrgNumber')
    // t('field_minutesduration', 'MinutesDuration')
    // t('field_planneddate', 'PlannedDate')
    // t('field_plannedmonth', 'PlannedMonth')
    // t('field_plannedweek', 'PlannedWeek')
    // t('field_plannedyear', 'PlannedYear')
    // t('field_remarks', 'Remarks')
    // t('field_resolutionby', 'ResolutionBy')
    // t('field_resolutiondate', 'ResolutionDate')
    // t('field_resolutionmonth', 'ResolutionMonth')
    // t('field_resolutionweek', 'ResolutionWeek')
    // t('field_resolutionyear', 'ResolutionYear')
    // t('field_starteddate', 'StartedDate')
    // t('field_startedmonth', 'StartedMonth')
    // t('field_startedweek', 'StartedWeek')
    // t('field_startedyear', 'StartedYear')
    // t('field_status', 'Status')
    // t('field_type', 'Type')
    // t('field_audit_tag', 'Audit Tag')
    // t('field_audit_document_type', 'Audit Document Type')
    // t('field_audit_document_senddate', 'Audit Document SendDate')
    // t('field_audit_document_sendyear', 'Audit Document SendYear')
    // t('field_audit_document_sendweek', 'Audit Document SendWeek')
    // t('field_audit_document_sendmonth', 'Audit Document SendMonth')
    // t('field_audit_error_code', 'Audit Error Code')
    // t('field_audit_error_comment', 'Audit Error Comment')
    // t('field_audit_error_paragraph', 'Audit Error Paragraph')
    // t('field_audit_error_law', 'Audit Error Law')
    // t('field_audit_error_regulation', 'Audit Error Regulation')
    // t('field_audit_building_id', 'Audit Building Id')
    // t('field_audit_building_number', 'Audit Building Number')
    // t('field_audit_building_name', 'Audit Building Name')
    // t('field_audit_building_serialnumber', 'Audit Building SerialNumber')
    // t('field_audit_building_code', 'Audit Building Code')
    // t('field_audit_building_type', 'Audit Building Type')
    // t('field_audit_building_builddate', 'Audit Building BuildDate')
    // t('field_audit_building_industrialtype', 'Audit Building IndustrialType')
    // t('field_audit_building_riskassessmentscore', 'Audit Building RiskAssessmentScore')
    // t('field_audit_building_comment', 'Audit Building Comment')
    // t('field_audit_building_specifichealthcareobjectname', 'Audit Building SpecificHealthcareObjectName')
    // t('field_audit_specifichealthcareobject_id', 'Audit SpecificHealthcareObject Id')
    // t('field_audit_specifichealthcareobject_name', 'Audit SpecificHealthcareObject Name')
    // t('field_audit_specifichealthcareobject_comment', 'Audit SpecificHealthcareObject Comment')
    // t('field_audit_specifichealthcareobject_status', 'Audit SpecificHealthcareObject Status')
    // t('field_audit_specifichealthcareobject_responsibleuser', 'Audit SpecificHealthcareObject ResponsibleUser')
    // t('field_audit_specifichealthcareobject_type', 'Audit SpecificHealthcareObject Type')
    // t('field_audit_specifichealthcareobject_typedescription', 'Audit SpecificHealthcareObject TypeDescription')
    // HCBuildings base query:
    // t('field_housingunit_id', 'HousingUnit Id')
    // t('field_housingunit_lastcontroldate', 'HousingUnit LastControlDate')
    // t('field_housingunit_nextcontroldate', 'HousingUnit NextControlDate')
    // t('field_housingunit_address', 'HousingUnit Address')
    // t('field_housingunit_postalcode', 'HousingUnit PostalCode')
    // t('field_housingunit_type', 'HousingUnit Type')
    // t('field_housingunit_usablearea', 'HousingUnit UsableArea')
    // t('field_housingunit_inspectionrate', 'HousingUnit InspectionRate')
    // t('field_housingunit_riskassesment', 'HousingUnit RiskAssesment')
    // t('field_housingunit_attribute', 'HousingUnit Attribute')
    // t('field_housingunit_longitude', 'HousingUnit Longitude')
    // t('field_housingunit_latitude', 'HousingUnit Latitude')
    // t('field_housingunit_inspectionratesetby', 'HousingUnit InspectionRateSetBy')
    // t('field_housingunit_nextcontrolsetby', 'HousingUnit NextControlSetBy')
    // t('field_housingunit_district', 'HousingUnit District')
    // t('field_housingunit_residentialnumber', 'HousingUnit ResidentialNumber')
    // t('field_housingunit_basicstatisticalunitname', 'HousingUnit BasicStatisticalUnitName')
    // t('field_housingunit_basicstatisticalunitnumber', 'HousingUnit BasicStatisticalUnitNumber')
    // t('field_building_id', 'Building Id')
    // t('field_building_name', 'Building Name')
    // t('field_building_type', 'Building Type')
    // t('field_building_code', 'Building Code')
    // t('field_building_custom', 'Building Custom')
    // t('field_building_number', 'Building Number')
    // t('field_building_status', 'Building Status')
    // t('field_building_builddate', 'Building BuildDate')
    // t('field_building_industrialtype', 'Building IndustrialType')
    // t('field_building_lastcontroldate', 'Building LastControlDate')
    // t('field_building_nextcontroldate', 'Building NextControlDate')
    // t('field_building_riskassessment', 'Building RiskAssessment')
    // t('field_building_inspectionrate', 'Building InspectionRate')
    // t('field_building_longitude', 'Building Longitude')
    // t('field_building_latitude', 'Building Latitude')
    // t('field_building_coordinatestype', 'Building CoordinatesType')
    // t('field_building_attribute', 'Building Attribute')
    // t('field_building_specifichealthcareobject_id', 'Building SpecificHealthcareObject Id')
    // t('field_building_specifichealthcareobject_name', 'Building SpecificHealthcareObject Name')
    // t('field_building_specifichealthcareobject_comment', 'Building SpecificHealthcareObject Comment')
    // t('field_building_specifichealthcareobject_status', 'Building SpecificHealthcareObject Status')
    // t('field_building_specifichealthcareobject_responsibleuser', 'Building SpecificHealthcareObject ResponsibleUser')
    // t('field_building_specifichealthcareobject_type', 'Building SpecificHealthcareObject Type')
    // t('field_building_specifichealthcareobject_typedescription', 'Building SpecificHealthcareObject TypeDescription')
    // t('field_matrikkelunit_id', 'MatrikkelUnit Id')
    // t('field_matrikkelunit_number', 'MatrikkelUnit Number')
    // t('field_matrikkelunit_latitude', 'MatrikkelUnit Latitude')
    // t('field_matrikkelunit_longitude', 'MatrikkelUnit Longitude')
    // t('field_matrikkelunit_owners', 'MatrikkelUnit Owners')
    // t('field_matrikkelunit_municipalityname', 'MatrikkelUnit MunicipalityName')
    // t('field_matrikkelunit_municipalitynumber', 'MatrikkelUnit MunicipalityNumber')
    // NSO:
    //  t('field_audit_casenumber', 'Audit CaseNumber')
    //  t('field_audit_createddate', 'Audit CreatedDate')
    //  t('field_audit_createdmonth', 'Audit CreatedMonth')
    //  t('field_audit_createdweek', 'Audit CreatedWeek')
    //  t('field_audit_createdyear', 'Audit CreatedYear')
    //  t('field_audit_id', 'Audit Id')
    //  t('field_audit_status', 'Audit Status')
    //  t('field_company_attr', 'Company Attr')
    //  t('field_company_companytype', 'Company CompanyType')
    //  t('field_company_contact_email', 'Company Contact Email')
    //  t('field_company_contact_name', 'Company Contact Name')
    //  t('field_company_contact_phone', 'Company Contact Phone')
    //  t('field_company_contact_title', 'Company Contact Title')
    //  t('field_company_email', 'Company Email')
    //  t('field_company_foundeddate', 'Company FoundedDate')
    //  t('field_company_foundeddatemonth', 'Company FoundedDateMonth')
    //  t('field_company_foundeddateweek', 'Company FoundedDateWeek')
    //  t('field_company_foundeddateyear', 'Company FoundedDateYear')
    //  t('field_company_hasnosubunits', 'Company HasNoSubUnits')
    //  t('field_company_id', 'Company Id')
    //  t('field_company_inspectionareacode', 'Company InspectionAreaCode')
    //  t('field_company_inspectionareaname', 'Company InspectionAreaName')
    //  t('field_company_inspectionrate', 'Company InspectionRate')
    //  t('field_company_isauditobject', 'Company IsAuditObject')
    //  t('field_company_isinbusinessregistry', 'Company IsInBusinessRegistry')
    //  t('field_company_isinfoundationregistry', 'Company IsInFoundationRegistry')
    //  t('field_company_isinvatregistry', 'Company IsInVatRegistry')
    //  t('field_company_isinvoluntaryregistry', 'Company IsInVoluntaryRegistry')
    //  t('field_company_issubunit', 'Company IsSubUnit')
    //  t('field_company_lastcontrol', 'Company LastControl')
    //  t('field_company_lastcontrolmonth', 'Company LastControlMonth')
    //  t('field_company_lastcontrolweek', 'Company LastControlWeek')
    //  t('field_company_lastcontrolyear', 'Company LastControlYear')
    //  t('field_company_lastreceivedfinancialstatement', 'Company LastReceivedFinancialStatement')
    //  t('field_company_maincontactname', 'Company MainContactName')
    //  t('field_company_name', 'Company Name')
    //  t('field_company_nextcontrol', 'Company NextControl')
    //  t('field_company_nextcontrolmonth', 'Company NextControlMonth')
    //  t('field_company_nextcontrolweek', 'Company NextControlWeek')
    //  t('field_company_nextcontrolyear', 'Company NextControlYear')
    //  t('field_company_nso_actualnumberofemployees', 'Company NSO ActualNumberOfEmployees')
    //  t('field_company_nso_actualnumberofemployeesdate', 'Company NSO ActualNumberOfEmployeesDate')
    //  t('field_company_nso_chemicaldiver', 'Company NSO ChemicalDiver')
    //  t('field_company_nso_chemicalprotection', 'Company NSO ChemicalProtection')
    //  t('field_company_nso_dispensationreason', 'Company NSO DispensationReason')
    //  t('field_company_nso_emergencynetwork', 'Company NSO EmergencyNetwork')
    //  t('field_company_nso_fireprotection', 'Company NSO FireProtection')
    //  t('field_company_nso_firstaid', 'Company NSO FirstAid')
    //  t('field_company_nso_invoiceamount', 'Company NSO InvoiceAmount')
    //  t('field_company_nso_invoicecredited', 'Company NSO InvoiceCredited')
    //  t('field_company_nso_invoiceemailaddress', 'Company NSO InvoiceEmailAddress')
    //  t('field_company_nso_invoicenumber', 'Company NSO InvoiceNumber')
    //  t('field_company_nso_isfollowedup', 'Company NSO IsFollowedUp')
    //  t('field_company_nso_lawchapter', 'Company NSO LawChapter')
    //  t('field_company_nso_majoraccidentparagraph', 'Company NSO MajorAccidentParagraph')
    //  t('field_company_nso_noofemergencypersonnel', 'Company NSO NoOfEmergencyPersonnel')
    //  t('field_company_nso_notrequiredreason', 'Company NSO NotRequiredReason')
    //  t('field_company_nso_nsocode', 'Company NSO NSOCode')
    //  t('field_company_nso_nsonumber', 'Company NSO NSONumber')
    //  t('field_company_nso_orderandsecurity', 'Company NSO OrderAndSecurity')
    //  t('field_company_nso_otherreinforcement', 'Company NSO OtherReinforcement')
    //  t('field_company_nso_registrationyear', 'Company NSO RegistrationYear')
    //  t('field_company_nso_required', 'Company NSO Required')
    //  t('field_company_nso_responsiblepersonname', 'Company NSO ResponsiblePersonName')
    //  t('field_company_nso_sharedwithcivildefense', 'Company NSO SharedWithCivilDefense')
    //  t('field_company_nso_sharedwithfiredepartment', 'Company NSO SharedWithFireDepartment')
    //  t('field_company_nso_sharedwithhomesecurity', 'Company NSO SharedWithHomeSecurity')
    //  t('field_company_nso_smokediver', 'Company NSO SmokeDiver')
    //  t('field_company_numberofemployees', 'Company NumberOfEmployees')
    //  t('field_company_organizationnumber', 'Company OrganizationNumber')
    //  t('field_company_parent_name', 'Company Parent Name')
    //  t('field_company_parent_organizationnumber', 'Company Parent OrganizationNumber')
    //  t('field_company_phone', 'Company Phone')
    //  t('field_company_planttype', 'Company PlantType')
    //  t('field_company_postaladdress', 'Company PostalAddress')
    //  t('field_company_postalcountry', 'Company PostalCountry')
    //  t('field_company_postalmunicipalityname', 'Company PostalMunicipalityName')
    //  t('field_company_postalpostalcode', 'Company PostalPostalCode')
    //  t('field_company_registrationdate', 'Company RegistrationDate')
    //  t('field_company_registrationdatemonth', 'Company RegistrationDateMonth')
    //  t('field_company_registrationdateweek', 'Company RegistrationDateWeek')
    //  t('field_company_registrationdateyear', 'Company RegistrationDateYear')
    //  t('field_company_riskassesmentinstance_category_calculationtype', 'Company RiskAssesmentInstance Category CalculationType')
    //  t('field_company_riskassesmentinstance_category_element_score', 'Company RiskAssesmentInstance Category Element Score')
    //  t('field_company_riskassesmentinstance_category_element_selectedoption', 'Company RiskAssesmentInstance Category Element SelectedOption')
    //  t('field_company_riskassesmentinstance_category_element_title', 'Company RiskAssesmentInstance Category Element Title')
    //  t('field_company_riskassesmentinstance_category_score', 'Company RiskAssesmentInstance Category Score')
    //  t('field_company_riskassesmentinstance_category_title', 'Company RiskAssesmentInstance Category Title')
    //  t('field_company_riskassessment', 'Company RiskAssessment')
    //  t('field_company_riskassessmentinstance_calculatedscore', 'Company RiskAssessmentInstance CalculatedScore')
    //  t('field_company_riskassessmentinstance_instanceforinspectionrate', 'Company RiskAssessmentInstance InstanceForInspectionRate')
    //  t('field_company_riskassessmentinstance_score', 'Company RiskAssessmentInstance Score')
    //  t('field_company_riskassessmentinstance_scoredescription', 'Company RiskAssessmentInstance ScoreDescription')
    //  t('field_company_riskassessmentinstance_scoreinterval', 'Company RiskAssessmentInstance ScoreInterval')
    //  t('field_company_riskassessmentinstance_title', 'Company RiskAssessmentInstance Title')
    //  t('field_company_riskassessmentinstance_type', 'Company RiskAssessmentInstance Type')
    //  t('field_company_status', 'Company Status')
    //  t('field_company_totalsubunits', 'Company TotalSubUnits')
    //  t('field_company_visitingaddress', 'Company VisitingAddress')
    //  t('field_company_visitingaddresscountry', 'Company VisitingAddressCountry')
    //  t('field_company_visitingaddressmunicipalityname', 'Company VisitingAddressMunicipalityName')
    //  t('field_company_visitingpostalcode', 'Company VisitingPostalCode')
    //  t('field_company_webpage', 'Company WebPage')
    //  t('field_company_zonename', 'Company ZoneName')
    //  t('field_company_zonenumber', 'Company ZoneNumber')

    const translation = t('field_' + id, text);
    return translation.toLowerCase() === text.toLowerCase()
      ? translation
      : `${translation} [${text}]`;
  };
};
