import { map } from 'rxjs';
import ReportConfigClient from '../clients/ReportConfigClient';
import ReportsClient from '../clients/ReportsClient';
import { ErrorMessage } from '../models/request-result';
import { TenantSettings } from '../clients/TenantSettingsClient';

export const ACTION_LOAD_REPORT_GROUPS = 'ACTION_LOAD_REPORT_GROUPS';
export const ACTION_LOAD_FAVORITE_REPORTS = 'ACTION_LOAD_FAVORITE_REPORTS';
export const ACTION_LOAD_TENANT_CONFIGS = 'ACTION_LOAD_TENANT_CONFIGS';
export const ACTION_LOAD_USER_CONFIG = 'ACTION_LOAD_USER_CONFIG';
export const ACTION_SHOW_ERROR = 'ACTION_SHOW_ERROR';
export const ACTION_LOAD_TENANT_SETTINGS = 'ACTION_LOAD_TENANT_SETTINGS';

export const ReportsActions = {
  actionLoadReportGroups: () => (dispatch) => {
    ReportsClient.getReportList()
      .pipe(
        map((reports) => dispatch({ type: ACTION_LOAD_REPORT_GROUPS, reports }))
      )
      .subscribe();
  },
  actionLoadFavorites: () => (dispatch) => {
    ReportsClient.getReportFavoritesList()
      .pipe(
        map((reports) =>
          dispatch({ type: ACTION_LOAD_FAVORITE_REPORTS, reports })
        )
      )
      .subscribe();
  },
  actionLoadTenantConfigs: () => (dispatch) => {
    ReportConfigClient.GetTenantConfigs()
      .pipe(
        map((tenants) =>
          dispatch({ type: ACTION_LOAD_TENANT_CONFIGS, tenants: tenants })
        )
      )
      .subscribe();
  },
  actionLoadUserConfig: () => (dispatch) => {
    ReportsClient.getUserConfig()
      .pipe(
        map((userConfig) =>
          dispatch({ type: ACTION_LOAD_USER_CONFIG, userConfig: userConfig })
        )
      )
      .subscribe();
  },
  actionShowError: (messages: ErrorMessage[]) => (dispatch) => {
    dispatch({ type: ACTION_SHOW_ERROR, messages });
  },
  actionLoadTenantSettings: (sessionKey: string) => (dispatch) => {
    TenantSettings(sessionKey)
      .pipe(
        map((settings) =>
          dispatch({
            type: ACTION_LOAD_TENANT_SETTINGS,
            settings: settings,
          })
        )
      )
      .subscribe();
  },
};
