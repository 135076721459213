import { FilterObject } from '../../models/filter-object';
import { ReportFilterInfo } from '../../models/report-filter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportFilterOperator } from '../../models/report-details';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { MenuItem, Select } from '@mui/material';
import { formatDate } from '../localization-helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    '& .MuiOutlinedInput-root': {
      padding: '10px',
    },
  },
  input: {
    width: 'auto',
    '& .MuiOutlinedInput-root': {
      maxWidth: '200px',
    },
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    backgroundColor: '#fff',
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
  },
}));
export const DateTimeRangeFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  const { filter, pendingFilters, setPendingFilters } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);

    if (option === 'today') {
      handleTodaySelect();
    } else if (option === 'yesterday') {
      handleYesterdaySelect();
    } else if (option === 'currentWeek') {
      handleCurrentWeekSelect();
    } else if (option === 'previousWeek') {
      handleLastWeekSelect();
    } else if (option === 'currentMonth') {
      handleCurrentMonthSelect();
    } else if (option === 'previousMonth') {
      handleLastMonthSelect();
    } else if (option === 'currentYear') {
      handleCurrentYearSelect();
    } else if (option === 'previousYear') {
      handleLastYearSelect();
    }
  };

  const handleTodaySelect = () => {
    const endDate = DateTime.now().startOf('day');
    const startDate = endDate;
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleYesterdaySelect = () => {
    const endDate = DateTime.now().startOf('day').minus({ days: 1 });
    const startDate = endDate;
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleCurrentWeekSelect = () => {
    const endDate = DateTime.now().startOf('day');
    const startDate = endDate.startOf('week');
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleLastWeekSelect = () => {
    const today = DateTime.now().startOf('day');
    const endDate = today.minus({ days: today.weekday }); // Set the end date to the previous Sunday
    const startDate = endDate.minus({ days: 6 }); // Set the start date to the previous Monday
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleCurrentMonthSelect = () => {
    const endDate = DateTime.now().startOf('day');
    const startDate = endDate.startOf('month');
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleLastMonthSelect = () => {
    const endDate = DateTime.now().startOf('month').minus({ days: 1 });
    const startDate = endDate.startOf('month');
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleCurrentYearSelect = () => {
    const endDate = DateTime.now().startOf('day');
    const startDate = endDate.startOf('year');
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };

  const handleLastYearSelect = () => {
    const endDate = DateTime.now()
      .startOf('day')
      .minus({ years: 1 })
      .endOf('year');
    const startDate = endDate.startOf('year');
    handleChangeFrom(startDate);
    handleChangeTo(endDate);
  };
  var currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const styles = useStyles();
  useEffect(() => {
    if (
      filter.defaultValueMin &&
      filter.operator !== ReportFilterOperator.LessThan
    ) {
      handleChangeFrom(DateTime.fromISO(filter.defaultValueMin));
    }
    if (
      filter.defaultValueMax &&
      filter.operator !== ReportFilterOperator.GreaterThan
    ) {
      handleChangeTo(DateTime.fromISO(filter.defaultValueMax));
    }
  }, []);
  function handleChangeFrom(date: DateTime) {
    const formattedDate = date.startOf('day').toISO(); // Format the date
    setFrom(formattedDate);

    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId == filter.filterId
    );
    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        to: new Date(`${currentYear}-12-31`),
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }
    filterItem.from = date.toJSDate();
    if (filter.operator === ReportFilterOperator.LessThan) {
      filterItem.to = null;
    }
    setPendingFilters({ ...pendingFilters });
  }

  function handleChangeTo(date: DateTime) {
    const formattedDate = date.startOf('day').toISO(); // Format the date
    console.log(formattedDate);
    console.log(new Date());

    setTo(formattedDate);
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId == filter.filterId
    );
    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        from: new Date(`${currentYear}-01-01`),
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }
    filterItem.to = date.toJSDate();
    if (filter.operator === ReportFilterOperator.GreaterThan) {
      filterItem.from = null;
    }
    setPendingFilters({ ...pendingFilters });
  }

  if (filter.isHidden) {
    return <span></span>;
  }
  const inputFormat = formatDate();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupLabel}>{filter.displayName}</div>
      {filter.operator === ReportFilterOperator.InBetween && (
        <>
          <DatePicker
            label={t('FILTERS_FROM')}
            value={from ? DateTime.fromISO(from) : null}
            onChange={(date: DateTime) => handleChangeFrom(date)}
            format={inputFormat}
          />
          <div
            style={{
              padding: '0 10px',
              display: 'flex',
              alignItems: 'center',
            }}>
            -
          </div>
          <DatePicker
            label={t('FILTERS_TO')}
            value={to ? DateTime.fromISO(to) : null}
            defaultValue={
              filter.defaultValueMax
                ? DateTime.fromISO(filter.defaultValueMax)
                : undefined
            }
            onChange={(date: any) => handleChangeTo(date)}
            format={inputFormat}
          />
          <Select
            style={{ marginLeft: '24px', minWidth: '250px' }}
            value={selectedOption}
            label='Select date'
            displayEmpty
            onChange={(event) =>
              handleOptionSelect(event.target.value as string)
            }>
            <MenuItem value='' disabled>
              {t('SELECT_DATE_RANGE', 'velg datoperiode')}
            </MenuItem>
            <MenuItem value='today'>{t('TODAY', 'I dag')}</MenuItem>
            <MenuItem value='yesterday'>{t('YESTERDAY', 'I går')}</MenuItem>
            <MenuItem value='currentWeek'>
              {t('CURRENT_WEEK', 'Nåværende uke')}
            </MenuItem>
            <MenuItem value='previousWeek'>
              {t('PREVIOUS_WEEK', 'Previous week')}
            </MenuItem>
            <MenuItem value='currentMonth'>
              {t('CURRENT_MONTH', 'Forrige uke')}
            </MenuItem>
            <MenuItem value='previousMonth'>
              {t('PREVIOUS_MONTH', 'Forrige måned')}
            </MenuItem>
            <MenuItem value='currentYear'>
              {t('CURRENT_YEAR', 'Dette året')}
            </MenuItem>
            <MenuItem value='previousYear'>
              {t('PREVIOUS_YEAR', 'Forrige år')}
            </MenuItem>
          </Select>
        </>
      )}
      {(filter.operator === ReportFilterOperator.GreaterThan ||
        filter.operator === ReportFilterOperator.Equals) && (
        <DatePicker
          label={t('FILTERS_FROM')}
          minDate={
            filter.defaultValueMin
              ? DateTime.fromISO(filter.defaultValueMin)
              : undefined
          }
          onChange={(date: DateTime) => handleChangeFrom(date)}
          format={inputFormat}
        />
      )}
      {filter.operator === ReportFilterOperator.LessThan && (
        <DatePicker
          label={t('FILTERS_TO')}
          maxDate={
            filter.defaultValueMax
              ? DateTime.fromISO(filter.defaultValueMax)
              : undefined
          }
          onChange={(date: any) => handleChangeTo(date)}
          format={inputFormat}
        />
      )}
    </div>
  );
};
