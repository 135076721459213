import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ReportInfo } from '../models/report-info';
import { useDispatch, useSelector } from 'react-redux';
import ReportConfigClient from '../clients/ReportConfigClient';
import { ReportsActions } from '../redux/reports-actions';
import { Subscription } from 'rxjs';
import { useEffect, useState } from 'react';
import { AppState } from '../redux/app-store';

const useStyles = makeStyles((theme: Theme) => ({
  draftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },
  favouriteIcon: {
    color: '#385690',
  },
  button: {
    padding: '0px',
    '& .MuiButtonBase-root-MuiIconButton-root': {
      padding: '0px',
    }
  }

}));
type Props = {
  report: ReportInfo;
};
export const FavouriteChip: React.FC<Props> = ({ report }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [busySub, setBusySub] = useState<Subscription>(null);
  const [isFavorite, setIsFavorite] = useState(report.isFavorite);
  const dispatch = useDispatch();
const favoriteReports = useSelector(
  (state: AppState) => state.reports.reportFavoriteList
);
// const favoriteReportsList = favoriteReports.reduce((acc, reportGroup) => {
//   return acc.concat(reportGroup.reports.filter(report => report.isFavorite));
// }, []); 
  
  const markFavourite = (e,reportCode) => {
    console.log(report);
    e.stopPropagation()

    const sub = ReportConfigClient.markReportFavourite(reportCode).subscribe(
      (result) => {
        ReportsActions.actionLoadFavorites()(dispatch);
      }
    );
    setIsFavorite(true)
    setBusySub(sub);
  };
  const unmarkFavourite = (e,reportCode) => {
    e.stopPropagation()
    const sub = ReportConfigClient.unmarkReportFavourite(reportCode).subscribe(
      (result) => {
        ReportsActions.actionLoadFavorites()(dispatch);
      }
    );
    setIsFavorite(false)
    setBusySub(sub);
  };

  return (
    <div className={styles.draftContainer}>
      {isFavorite ? (
        <IconButton className={styles.button} onClick={(e) => unmarkFavourite(e,report.code)}>
          <StarIcon className={styles.favouriteIcon} />
        </IconButton>
      ) : (
        <IconButton className={styles.button} onClick={(e) => markFavourite(e,report.code)}>
          <StarBorderIcon/>
        </IconButton>
      )}
    </div>
  );
};
