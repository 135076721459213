import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReportGroupInfo } from '../../models/report-info';
import { FavouriteChip } from '../favourite-chip';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SortableList } from '../sorting/sortable-list';
import { Button, IconButton, Tooltip } from '@mui/material';
import { EditReportGroupsDialogV2 } from './report-groups-dialog-v2';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/app-store';

const useStyles = makeStyles(() => ({
  ReportGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: '40px',
    paddingBottom: '50px',
    width: '33.3%',
    minWidth: '33.3%',
    backgroundColor: '#fff',
  },
  sortableListContainer: {
    maxWidth: 400,
    margin: '30px 0',
  },
  ReportGroupHeaderContainer: {
    borderBottom: 'solid black 1px',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '0 6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reportContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    paddingLeft: '16px',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
  },
  listButtons: {
    color: 'grey',
  },
  emptyGroupMessage: {
    paddingLeft: '16px',
  },
  dragHandleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  itemRightElements: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
type Props = {
  reportGroup?: ReportGroupInfo;
};

export const DashboardReportGroup: React.FC<Props> = ({ reportGroup }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [reports, setReports] = useState(reportGroup.reports);
  const searchValue = useSelector(
    (state: AppState) => state.dashboard.searchTerm
  );
  const [showAll, setShowAll] = useState(
    searchValue.length >= 3 ? true : false
  );
  useEffect(() => {
    if (searchValue.length >= 3) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
    if (searchValue.length < 3) {
      setReports(reportGroup.reports);
    }
  }, [searchValue, reportGroup.reports]);

  return (
    <>
      <div className={styles.ReportGroupContainer}>
        <div className={styles.ReportGroupHeaderContainer}>
          <div>{reportGroup.label}</div>

          <div>
            {reportGroup.isGroupEditAllowed ? (
              <EditReportGroupsDialogV2 reportGroup={reportGroup} />
            ) : (
              <Tooltip
                title={t(
                  'GLOBAL_GROUP_TOOLTIP',
                  'This is a global report group and it can not be modified.'
                )}>
                <IconButton disabled>
                  <LockIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>

        <div>
          <div className={styles.sortableListContainer}>
            {reports.length ? (
              <>
                <SortableList
                  items={reports}
                  showAll={showAll}
                  onChange={setReports}
                  renderItem={(item) => (
                    <SortableList.Item id={item.id}>
                      <div
                        onClick={() =>
                          history.push(
                            `/reports/${reportGroup.code}/${item.code}`
                          )
                        }
                        className={styles.reportContainer}>
                        <div className={styles.dragHandleContainer}>
                          <SortableList.DragHandle />
                          <div>{item.label}</div>
                        </div>

                        <div className={styles.itemRightElements}>
                          <FavouriteChip report={item} />

                          <KeyboardArrowRightIcon />
                        </div>
                      </div>
                    </SortableList.Item>
                  )}
                />
                <div className={styles.viewMore}>
                  {searchValue.length >= 3 ? (
                    <></>
                  ) : showAll ? (
                    <>
                      {reports.length > 5 && (
                        <Button
                          className={styles.listButtons}
                          onClick={() => setShowAll(false)}>
                          {t('SHOW_LESS', 'Vis mindre')}
                          <KeyboardArrowUpIcon />
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {reports.length > 5 && (
                        <Button
                          className={styles.listButtons}
                          onClick={() => setShowAll(true)}>
                          {t('VIEW_ALL', 'Vis alt')}
                          <KeyboardArrowDownIcon />
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className={styles.emptyGroupMessage}>
                {t('NO_REPORTS_IN_GROUP', 'Ingen rapporter i denne gruppen.')}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
