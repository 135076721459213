import React, { useState } from 'react';
import { Button, Drawer, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HistoryIcon from '@mui/icons-material/History';
import { HistoryCard } from './history-card';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    fontWeight: 'bold',
    padding: '10px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
export const HistoryDrawer = ({
  events,
  setNewReport,
  selectedGroup,
  setSelectedReport,
  setSavingDeletingSub,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const styles = useStyles();
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Button
        variant='contained'
        style={{
          position: 'fixed',
          top: '120px',
          right: '0px',
          padding: '12px',
          backgroundColor: '#fff',
          color: '#3f558c',
        }}
        onClick={handleDrawerOpen}>
        <HistoryIcon />
      </Button>
      <Drawer
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
        // sx={{maxWidth: '100px'}}
        anchor='right'
        open={isDrawerOpen}
        onClose={handleDrawerClose}>
        <div style={{ padding: '5px' }}>
          <div className={styles.cardHeader}>
            <div> {t('CHANGELOG', 'ENDRINGSLOGG')}</div>
            <div>
              <Button
                style={{
                  padding: '0px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                onClick={handleDrawerClose}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          {events
            .sort((a, b) =>
              new Date(a.timestamp) < new Date(b.timestamp) ? 1 : -1
            )
            .map((event, index) => (
              <HistoryCard
                key={index}
                selectedGroup={selectedGroup}
                handleDrawerClose={handleDrawerClose}
                setNewReport={setNewReport}
                setSelectedReport={setSelectedReport}
                setSavingDeletingSub={setSavingDeletingSub}
                event={event}
              />
            ))}
        </div>
      </Drawer>
    </>
  );
};
