import { Observable } from "rxjs";
import { ReportBaseQuery, ReportDefinition, ReportFilter, ReportGroup, SaveReportGroup } from "../models/report-details";
import { ReportFilterOption } from "../models/report-filter";
import { BaseQueryColumnInfo } from "../models/report-info";
import { SaveReport } from "../models/save-report";
import { TenantConfiguration } from "../models/tenant-config";
import { FetchService } from "../services/FetchService";

export default class ReportConfigClient {
  public static getAllBaseQueries(): Observable<ReportBaseQuery[]> {
    return FetchService.DoWrappedGet(`/report-config/base-queries`);
  }
  public static getReportPotentialColumns(baseQueryCode: string): Observable<BaseQueryColumnInfo[]> {
    return FetchService.DoWrappedGet(`/report-config/columns/${baseQueryCode}`);
  }
  public static getReportGroupDetails(code: string): Observable<ReportGroup> {
    return FetchService.DoWrappedGet(`/report-config/report-group-details/${code}`);
  }
  public static saveReport(report: SaveReport): Observable<ReportDefinition> {
    return FetchService.DoWrappedPost(`/report-config/save-report`, report);
  }
  public static revertReport(historyId: number): Observable<ReportDefinition> {
    return FetchService.DoWrappedGet(`/report-config/revert-report/${historyId}`);
  }
  public static saveReportGroup(reportGroup: SaveReportGroup): Observable<ReportGroup> {
    return FetchService.DoWrappedPost(`/report-config/save-report-group`, reportGroup);
  }
  public static deleteReport(reportCode: string): Observable<void> {
    return FetchService.DoWrappedGet(`/report-config/delete-report/${reportCode}`);
  }
  public static deleteReportGroup(reportGroupCode: string): Observable<void> {
    return FetchService.DoWrappedGet(`/report-config/delete-report-group/${reportGroupCode}`);
  }
  public static getBaseQueryColumnValues(baseQueryCode: string, sqlColumn: string): Observable<ReportFilterOption[]> {
    return FetchService.DoWrappedGet(`/report-config/query-column-values/${baseQueryCode}/${sqlColumn}`);
  }
  public static GetTenantConfigs(): Observable<TenantConfiguration[]> {
    return FetchService.DoWrappedGet(`/report-config/tenant-configs`);
  }
  public static markReportFavourite(reportCode: string): Observable<void> {
    return FetchService.DoWrappedPost(`/report-config/mark-report-favorite/${reportCode}`, reportCode);
  }
  public static unmarkReportFavourite(reportCode: string): Observable<void> {
    return FetchService.DoWrappedPost(`/report-config/unmark-report-favorite/${reportCode}`, reportCode);
  }
  public static sortReport(reportCode: string, sortId: number): Observable<void> {
    return FetchService.DoWrappedPost(`/report-config/sort-report/${reportCode}/${sortId}`, {reportCode, sortId});
  }
}