import { AnyAction } from 'redux';
import { ReportsState } from '../models/reports-state';
import { makeReducer } from '../utils';
import { Reducers } from '../utils/make-reducer';
import {
  ACTION_LOAD_REPORT_GROUPS,
  ACTION_LOAD_FAVORITE_REPORTS,
  ACTION_LOAD_TENANT_CONFIGS,
  ACTION_LOAD_USER_CONFIG,
  ACTION_SHOW_ERROR,
  ACTION_LOAD_TENANT_SETTINGS,
} from './reports-actions';

type ReportsReducer = (state: ReportsState, action: AnyAction) => ReportsState;

const initialState: ReportsState = {
  reportGroupList: [],
  tenantConfigs: [],
  errorMessages: [],
  reportFavoriteList: [],
  tenantSettings: [],
};

const loadReports: ReportsReducer = (state, action) => {
  state.reportGroupList = action.reports;
  return state;
};

const loadFavoriteReports: ReportsReducer = (state, action) => {
  state.reportFavoriteList = action.reports;
  return state;
};

const loadTenantConfigs: ReportsReducer = (state, action) => {
  state.tenantConfigs = action.tenants;
  return state;
};

const loadUserConfig: ReportsReducer = (state, action) => {
  state.userConfig = action.userConfig;
  return state;
};

const showError: ReportsReducer = (state, action) => {
  state.errorMessages = action.messages;
  return state;
};
const loadTenantSettings: ReportsReducer = (state, action) => {
  state.tenantSettings = action.settings;
  return state;
};

const reducers: Reducers<ReportsState> = {
  [ACTION_LOAD_REPORT_GROUPS]: loadReports,
  [ACTION_LOAD_FAVORITE_REPORTS]: loadFavoriteReports,
  [ACTION_LOAD_TENANT_CONFIGS]: loadTenantConfigs,
  [ACTION_LOAD_USER_CONFIG]: loadUserConfig,
  [ACTION_SHOW_ERROR]: showError,
  [ACTION_LOAD_TENANT_SETTINGS]: loadTenantSettings,
};

export const reportsReducer = makeReducer<ReportsState>(reducers, initialState);
