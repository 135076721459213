import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DynamicReport,
  DynamicReportGroupValue,
  DynamicReportSortingValue,
} from '../models/dynamic-report';
import { groupBy } from '../utils/common-utils';
import CubitTable from './cubit-table/cubit-table';
import { CubitTableColumn } from './cubit-table/cubit-table.types';

const useStyles = makeStyles((theme: Theme) => ({
  sortingLabels: {
    textAlign: 'left',
    paddingLeft: '17px',
  },
  sortingLabel: {
    padding: '4px 8px',
    background: '#ccc',
    borderRadius: '5px',
    margin: '6px 6px 6px 0',
    display: 'inline-block',
    cursor: 'pointer',
    color: '#385690',
    fontWeight: 'bold',
  },
  labelEnabled: {
    background: '#ccffdd',
    border: '1px solid black',
  },
  table: {
    margin: '0 16px',
  },
}));

type CountReportRow = {
  columnValues: string[];
  groupValues: DynamicReportGroupValue[];
};

export const DynamicReportTable: React.FC<{ reportGroup: DynamicReport }> = (
  props
) => {
  const styles = useStyles();
  const { t } = useTranslation();
  console.log(props.reportGroup);

  let report = props.reportGroup;

  const [enabledLabels, setEnabledLabels] = useState<string[]>([]);
  const [shownRows, setShownRows] = useState<CountReportRow[]>([]);
  const [showTotals, setShowTotals] = useState<boolean>(true);

  useEffect(() => {
    if (showTotals) {
      const groups = Array.from<DynamicReport[]>(
        groupBy<DynamicReport>(report.children, (x: DynamicReport) =>
          JSON.stringify(getChildrenColumns(x.sortingValues))
        ).values()
      );
      const newRows = groups.map((group) => {
        let grouped: DynamicReportGroupValue[] = [];
        groupBy<DynamicReportGroupValue>(
          group.flatMap((m) => m.groupValues),
          (x: DynamicReportGroupValue) => x.label
        ).forEach((m) => {
          grouped.push({
            label: m[0].label,
            count: m.reduce((a, b) => a + b.count, 0),
          });
        });

        return {
          columnValues: getChildrenColumns(group[0].sortingValues),
          groupValues: grouped,
        } as CountReportRow;
      });
      setShownRows(newRows);
    } else {
      const newRows = report.children.map((row) => {
        return {
          columnValues: getChildrenColumns(row.sortingValues),
          groupValues: [],
        } as CountReportRow;
      });
      setShownRows(newRows);
    }
  }, [enabledLabels]);

  useEffect(() => {
    const isShowTotals =
      report.children.length > 0 && report.children[0].groupValues.length > 0;
    setShowTotals(isShowTotals);
  }, []);

  const getChildrenColumns = (sortingValues: DynamicReportSortingValue[]) => {
    const shownColumns =
      enabledLabels.length == 0 ? report.sortingLabels : enabledLabels;
    return shownColumns.map((x) => {
      const val = sortingValues.find((v) => v.label == x);
      return val?.value || '-';
    });
  };

  const toggleLabel = (label: string) => {
    const newLabels = [...enabledLabels];

    if (newLabels.includes(label)) {
      newLabels.splice(newLabels.indexOf(label), 1);
    } else {
      newLabels.push(label);
    }
    setEnabledLabels(newLabels);
  };

  const toggleAllLabel = () => {
    setEnabledLabels([]);
  };

  const shownColumns =
    enabledLabels.length == 0 ? report.sortingLabels : enabledLabels;

  let columns: CubitTableColumn[] = [
    ...shownColumns.map((x) => ({
      headerLabel: x,
      key: x,
    })),
  ];

  if (showTotals && report.children.length > 0) {
    columns = [
      ...columns,
      ...report.children[0]?.groupValues?.map((x) => ({
        headerLabel: x.label,
        key: x.label,
      })),
    ];
  }

  const tableData = shownRows.map((row) => {
    const rowObj = {};
    shownColumns.forEach((col, index) => {
      rowObj[col] = row.columnValues[index];
    });
    row.groupValues.forEach((g) => (rowObj[g.label] = g.count));

    return rowObj;
  });

  return (
    <>
      {/* { showTotals && 
      <div className={styles.sortingLabels}>
        <b>{t("dynamic-reports.grouping-levels")}: </b>
        <span className={`${styles.sortingLabel} ${enabledLabels.length === 0 ? styles.labelEnabled : ""}`} onClick={() => toggleAllLabel()}>{t("general.all")}</span>
        {report.sortingLabels.map(label =>
          <span className={`${styles.sortingLabel} ${enabledLabels.includes(label) ? styles.labelEnabled : ""}`} onClick={() => toggleLabel(label)}>{label}</span>
        )}
      </div>
      } */}
      {columns.length > 0 && (
        <CubitTable
          sorting
          paging
          columns={columns}
          data={tableData}
          name='dynamic-report'
          className={styles.table}
        />
      )}
    </>
  );
};
