import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import ReportConfigClient from '../../clients/ReportConfigClient';
import {
  ReportFilter,
  ReportFilterInputType,
  ReportFilterOperator,
} from '../../models/report-details';
import { ReportFilterOption } from '../../models/report-filter';
import { BaseQueryColumnInfo } from '../../models/report-info';
import { CubitCheckboxAdapter } from '../cubit-inputs/react-form-adapters/cubit-checkbox-adapter';
import { CubitSelectAdapter } from '../cubit-inputs/react-form-adapters/cubit-select-adapter';
import { CubitTextFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-text-field-adapter';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { CubitAutocompleteAdapter } from '../cubit-inputs/react-form-adapters/cubit-autocomplete-adapter';
import {
  BooleanSelectItems,
  ClusionTypeItems,
  InputTypeItems,
  OperatorSelectItems,
  getColumnInfo,
  getDefaultValue,
  getFieldComponent,
  getFieldNameByOperator,
  getFilterType,
  getOperatorValue,
  sqlColumnIsListType,
} from './filter-edit-helper';
import { ColumnText, processedColumns } from './config-helper';
import { AppState } from '../../redux/app-store';
import { useAuth } from '../../redux/auth/auth-selectors';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: '8px 0 16px ',
    minWidth: '80%',
  },
  formField: {
    width: '100%',
  },
  loader: {
    position: 'relative',
    width: 'calc(80% + 16px)',
    textAlign: 'center',
    margin: '16px 0',
  },
  editIconContainer: {
    width: '100%',
    borderBottom: 'solid #000 1px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldValueContainer: {
    display: 'flex',
    padding: '12px 0 0 24px',
  },
}));

const SelectedFilterForm: React.FC<
  InjectedFormProps<ReportFilter> & {
    baseQueryCode: string;
    potentialColumns: BaseQueryColumnInfo[];
    setSelectedFilter: any;
    selectedFilter: any;
    setNewFilter: any;
    newFilter: any;
    newFilters: any;
    selectedReport: any;
    setHasUnsavedChanges: any;
    index: number;
    open: boolean;
    baseQuery: string;
  }
> = (props) => {
  const {
    handleSubmit,
    setSelectedFilter,
    selectedFilter,
    form,
    baseQueryCode,
    potentialColumns,
    newFilters,
    baseQuery,
  } = props;
  const styles = useStyles();
  const [isOpen, setOpen] = useState(newFilters?.length ? true : false);
  const [columnValues, setColumnValues] = useState<ReportFilterOption[]>([]);
  const { t } = useTranslation();
  const columnText = ColumnText(t);
  const values = useSelector((state) =>
    getFormValues(form)(state)
  ) as ReportFilter;
  useEffect(() => {
    if (values.sqlColumn && sqlColumnIsListType(potentialColumns, values)) {
      setColumnValues(null);
      ReportConfigClient.getBaseQueryColumnValues(
        baseQueryCode,
        values.sqlColumn
      ).subscribe((x) => {
        setColumnValues(x);
      });
    }
  }, [baseQueryCode, potentialColumns, values, values.sqlColumn]);
  const lanternUrl = (url) => process.env.REACT_APP_LANTERN_API_URL + url;
  const auth = useAuth();

  console.log(lanternUrl(`/api/tenantsettings/${auth.sessionKey}`));

  return (
    <>
      <div className={styles.editIconContainer}>
        <div>{values?.displayName}</div>
        <div>
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div>
        <div className={styles.fieldValueContainer}>
          <div style={{ minWidth: '200px', color: ' rgba(0, 0, 0, 0.55)' }}>
            {t('SQL_COLONNE', 'SQL kolonne')}
          </div>
          <div>{columnText(selectedFilter?.sqlColumn)}</div>
        </div>
        <div className={styles.fieldValueContainer}>
          <div style={{ minWidth: '200px', color: ' rgba(0, 0, 0, 0.55)' }}>
            {t('FILTER_TYPE', 'Filtertype')}
          </div>
          <div>{getFilterType(values?.reportInputType, t)}</div>
        </div>
        <div className={styles.fieldValueContainer}>
          <div style={{ minWidth: '200px', color: ' rgba(0, 0, 0, 0.55)' }}>
            {t('OPERATOR', 'Operatør')}
          </div>
          <div>{getOperatorValue(values?.operator, t)}</div>
        </div>
        {values.reportInputType &&
          values.reportInputType !== ReportFilterInputType.MultiSelect &&
          values.reportInputType !== ReportFilterInputType.SelectList && (
            <>
              {values.operator !== ReportFilterOperator.InBetween &&
                values.reportInputType !== ReportFilterInputType.Boolean &&
                getDefaultValue(values.operator, values) && (
                  <div className={styles.fieldValueContainer}>
                    <div
                      style={{
                        minWidth: '200px',
                        color: ' rgba(0, 0, 0, 0.55)',
                      }}>
                      {t('DEFAULT_VALUE', 'standardverdi')}
                    </div>
                    <div>{getDefaultValue(values.operator, values)}</div>
                  </div>
                )}
              {values.reportInputType === ReportFilterInputType.Boolean && (
                <div className={styles.fieldValueContainer}>
                  <div
                    style={{
                      minWidth: '200px',
                      color: ' rgba(0, 0, 0, 0.55)',
                    }}>
                    {t('DEFAULT_VALUE', 'Standardverdi')}
                  </div>
                  <div>{getDefaultValue(values.operator, values)}</div>
                </div>
              )}
              {values.operator === ReportFilterOperator.InBetween && (
                <>
                  <div className={styles.fieldValueContainer}>
                    <div
                      style={{
                        minWidth: '200px',
                        color: ' rgba(0, 0, 0, 0.55)',
                      }}>
                      {t('DEFAULT_VALUE_MINIMUM', 'Minimum standardverdi')}
                    </div>
                    <div>{values.defaultValueMin}</div>
                  </div>
                  <div className={styles.fieldValueContainer}>
                    <div
                      style={{
                        minWidth: '200px',
                        color: ' rgba(0, 0, 0, 0.55)',
                      }}>
                      {t('DEFAULT_VALUE_MAXIMUM', 'Maksimal standardverdi')}
                    </div>
                    <div>{values.defaultValueMax}</div>
                  </div>
                </>
              )}
              <div className={styles.fieldValueContainer}>
                <div
                  style={{
                    minWidth: '200px',
                    color: ' rgba(0, 0, 0, 0.55)',
                  }}>
                  {t('HIDDEN', 'Skjult')}
                </div>
                <div>{values.isHidden === true ? 'Ja' : 'Nei'}</div>
              </div>
            </>
          )}
      </div>
      {isOpen && (
        <Dialog
          sx={{ padding: '16px' }}
          maxWidth={'xs'}
          fullWidth={true}
          open={isOpen}
          onClose={() => setOpen(false)}>
          <DialogTitle>{t('EDIT_FILTER', 'Endre filter')}</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <div style={{ height: '100%' }}>
                <div style={{ flex: 1 }}>
                  <div className={styles.formControl}>
                    <div>
                      <Field
                        formcontrolprops={{
                          className: styles.formField,
                        }}
                        name='displayName'
                        label={t('TITLE', 'Tittel')}
                        component={CubitTextFieldAdapter}
                      />
                    </div>
                  </div>
                  <div className={styles.formControl}>
                    <div>
                      <Field
                        type='select'
                        formcontrolprops={{
                          className: styles.formField,
                        }}
                        initialValue={values?.sqlColumn}
                        defaultValue={values?.sqlColumn}
                        // value={values?.sqlColumn}
                        setSelectedFilter={setSelectedFilter}
                        selectedFilter={selectedFilter}
                        name='selectColumn'
                        label={t('SQL_COLUMN', 'SQL kolonne')}
                        component={CubitAutocompleteAdapter}
                        options={processedColumns(
                          baseQuery,
                          potentialColumns,
                          t
                        )}
                      />
                    </div>
                  </div>

                  <div className={styles.formControl}>
                    {selectedFilter.sqlColumn && (
                      <div>
                        <Field
                          type='select'
                          formcontrolprops={{
                            className: styles.formField,
                          }}
                          name='reportInputType'
                          label={t('FILTER_TYPE', 'Filtertype')}
                          valueIsObject={false}
                          component={CubitSelectAdapter}
                          options={InputTypeItems(t).filter((x) =>
                            x.dataTypes.includes(
                              getColumnInfo(potentialColumns, selectedFilter)
                                ?.dataType
                            )
                          )}
                        />
                      </div>
                    )}
                    {selectedFilter.sqlColumn && (
                      <div>
                        <Field
                          type='select'
                          formcontrolprops={{ className: styles.formField }}
                          name='operator'
                          label={t('FILTER_TYPE', 'Operatør')}
                          valueIsObject={false}
                          component={CubitSelectAdapter}
                          options={OperatorSelectItems(t).filter(
                            (x) =>
                              values.reportInputType &&
                              x.allowedInputTypes.includes(
                                values.reportInputType
                              )
                          )}
                        />
                      </div>
                    )}
                  </div>
                  {values.reportInputType &&
                    values.reportInputType !==
                      ReportFilterInputType.MultiSelect &&
                    values.reportInputType !==
                      ReportFilterInputType.SelectList && (
                      <>
                        <div className={styles.formControl}>
                          {values.operator !== ReportFilterOperator.InBetween &&
                            values.reportInputType !==
                              ReportFilterInputType.Boolean && (
                              <div>
                                <Field
                                  formcontrolprops={{
                                    className: styles.formField,
                                  }}
                                  name={getFieldNameByOperator(
                                    values.operator,
                                    t
                                  )}
                                  label={t('DEFAULT_VALUE', 'Standardverdi')}
                                  component={getFieldComponent(6)}
                                />
                              </div>
                            )}
                          {values.reportInputType ===
                            ReportFilterInputType.Boolean && (
                            <div>
                              <Field
                                type='select'
                                formcontrolprops={{
                                  className: styles.formField,
                                }}
                                name='defaultValueMin'
                                label={t('DEFAULT_VALUE', 'Standardverdi')}
                                valueIsObject={false}
                                component={CubitSelectAdapter}
                                options={BooleanSelectItems(t)}
                              />
                            </div>
                          )}
                          {values.operator ===
                            ReportFilterOperator.InBetween && (
                            <>
                              <div>
                                <Field
                                  formcontrolprops={{
                                    className: styles.formField,
                                  }}
                                  name='defaultValueMin'
                                  label={t(
                                    'DEFAULT_VALUE_MINIMUM',
                                    'Minimum standardverdi'
                                  )}
                                  component={getFieldComponent(6)}
                                />
                              </div>
                              <div>
                                <Field
                                  formcontrolprops={{
                                    className: styles.formField,
                                  }}
                                  name='defaultValueMax'
                                  label={t(
                                    'DEFAULT_VALUE_MAXIMUM',
                                    'Maksimal standardverdi'
                                  )}
                                  component={getFieldComponent(6)}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <FormControl
                          variant='standard'
                          className={styles.formControl}>
                          <Field
                            name='isHidden'
                            label={t('HIDDEN', 'Skjult')}
                            component={CubitCheckboxAdapter}
                          />
                        </FormControl>
                      </>
                    )}

                  {!columnValues && (
                    <div className={styles.loader}>
                      <TailSpin
                        wrapperStyle={{ display: 'inline-block' }}
                        color='#385690'
                        height={32}
                        width={32}
                      />
                    </div>
                  )}
                  {columnValues && (
                    <>
                      {((sqlColumnIsListType(potentialColumns, values) &&
                        values.reportInputType ===
                          ReportFilterInputType.MultiSelect) ||
                        values.reportInputType ===
                          ReportFilterInputType.MultiSelectText ||
                        values.reportInputType ===
                          ReportFilterInputType.SelectList) && (
                        <div>
                          <Field
                            type='select'
                            formcontrolprops={{
                              className: styles.formField,
                            }}
                            name='clusionType'
                            label={t(
                              'INCLUDE_OR_EXCLUDE_OPTIONS',
                              'Inkludere/ekskludere alternativer'
                            )}
                            valueIsObject={false}
                            component={CubitSelectAdapter}
                            options={ClusionTypeItems(t)}
                          />
                        </div>
                      )}
                      {values.clusionType !== 'all' &&
                        sqlColumnIsListType(potentialColumns, values) &&
                        (values.reportInputType ===
                          ReportFilterInputType.MultiSelect ||
                          values.reportInputType ===
                            ReportFilterInputType.MultiSelectText ||
                          values.reportInputType ===
                            ReportFilterInputType.SelectList) && (
                          <div>
                            <Field
                              type='select'
                              formcontrolprops={{
                                className: styles.formField,
                              }}
                              name='reportDefinitions'
                              label={
                                values.clusionType === 'include'
                                  ? t('INCLUDE_VALUES', 'Inkludere verdier')
                                  : t('EXCLUDE_VALUES', 'Ekskluder verdier')
                              }
                              valueIsObject={false}
                              component={CubitSelectAdapter}
                              options={columnValues.map((x) => ({
                                label: x.value,
                                value: x.code,
                              }))}
                            />
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant={'text'} onClick={() => setOpen(false)}>
                {t('CANCEL', 'Avbryt')}
              </Button>
              <Button
                variant={'contained'}
                onClick={handleSubmit}
                type='submit'>
                {t('save.filter', 'Lagre filter')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};
export const SelectedFilterBlock = reduxForm<
  ReportFilter,
  {
    potentialColumns: BaseQueryColumnInfo[];
    baseQuery: string;
    baseQueryCode: string;
    index: any;
    setSelectedFilter: any;
    selectedFilter: any;
    setNewFilters: any;
    newFilters: any;
    selectedReport: any;
    setHasUnsavedChanges: any;
    open: boolean;
  }
>({
  form: 'selectedFilterForm',
  enableReinitialize: false,
})(SelectedFilterForm as any);
