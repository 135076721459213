import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import ReportConfigClient from '../../clients/ReportConfigClient';
import { ReportGroupInfo } from '../../models/report-info';
import { AppState } from '../../redux/app-store';
import { ReportsActions } from '../../redux/reports-actions';
import pascalcase from '../../utils/pascal-case';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDialog from '../confirm-dialog';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '80%',
    margin: '8px 0',
  },
  addIcon: {
    marginTop: '36px',
    marginLeft: '8px',
    width: '32px',
    height: '32px',
    color: '#44ef50',
    cursor: 'pointer',
  },
}));
type Props = {
  reportGroup: ReportGroupInfo;
};
export const EditReportGroupsDialogV2: React.FC<Props> = ({ reportGroup }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const tenantConfigs = useSelector(
    (state: AppState) => state.reports.tenantConfigs
  );
  const userConfig = useSelector((state: AppState) => state.reports.userConfig);
  // const [selectedGroup, setselectedGroup] = useState<SaveReportGroup>({
  //   tenantIds: [],
  //   displayName: 'New group',
  //   code: pascalcase('New group'),
  //   categoryName: '',
  //   isGloballyVisible: userConfig?.isReportVisibilityManagementAllowed ?? false,
  // });
  const [busySub, setBusySub] = useState<Subscription>(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const styles = useStyles();
  useEffect(() => {
    ReportConfigClient.getReportGroupDetails(reportGroup.code).subscribe(
      (x) => {
        setSelectedGroup(x);
        setBusySub(null);
      }
    );
  }, []);
  const deleteGroup = () => {
    setDeleteConfirmOpen(false);
    if (!!selectedGroup) {
      const sub = ReportConfigClient.deleteReportGroup(
        selectedGroup.code
      ).subscribe((result) => {
        setSelectedGroup(null);
        ReportsActions.actionLoadReportGroups()(dispatch);
        setBusySub(null);
      });
      setBusySub(sub);
    }
  };

  const saveGroup = () => {
    const sub = ReportConfigClient.saveReportGroup(selectedGroup).subscribe(
      (result) => {
        ReportsActions.actionLoadReportGroups()(dispatch);
        setOpen(false);
      }
    );
    setBusySub(sub);
  };

  return (
    <>
      <div>
        <IconButton onClick={() => setOpen(true)}>
          <EditIcon sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
        </IconButton>
      </div>
      <Dialog
        open={open}
        maxWidth={'xs'}
        onClose={() => setOpen(false)}
        fullWidth
        aria-labelledby='new-reportgroup-dialog'>
        <DialogTitle id='new-reportgroup-dialog'>
          {t('MANAGE_GROUP', 'Endre gruppe')}
        </DialogTitle>
        <DialogContent>
          {!!selectedGroup && !busySub && (
            <>
              <TextField
                variant='standard'
                className={styles.formControl}
                label={t('TITLE', 'Tittel')}
                value={selectedGroup.displayName}
                onChange={(ev) => {
                  selectedGroup.displayName = ev.target.value as string;

                  if (!selectedGroup.id) {
                    selectedGroup.code = pascalcase(selectedGroup.displayName);
                  }
                }}></TextField>
              {/*<TextField*/}
              {/*  variant="standard"*/}
              {/*  label='Category name'*/}
              {/*  className={styles.formControl}*/}
              {/*  value={selectedGroup.categoryName}*/}
              {/*  onChange={(ev) => {*/}
              {/*    selectedGroup.categoryName = ev.target.value as string;*/}
              {/*    forceUpdate();*/}
              {/*  }}></TextField>*/}
              {userConfig?.isReportVisibilityManagementAllowed && (
                <div style={{ marginTop: 30 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedGroup.isGloballyVisible}
                        onChange={(ev) => {
                          selectedGroup.isGloballyVisible = ev.target.checked;
                        }}
                      />
                    }
                    label={t('IS_GLOBALLY_VISIBLE', 'Er globalt synlig')}
                  />
                  {!selectedGroup.isGloballyVisible && tenantConfigs && (
                    <div style={{ margin: '10px 0' }}>
                      <Autocomplete
                        filterSelectedOptions
                        id='combo-box-demo'
                        defaultValue={selectedGroup.allowedTenants.map(
                          (tenant) => ({
                            label: tenant.name,
                            id: tenant.tenantId,
                          })
                        )}
                        options={
                          tenantConfigs &&
                          tenantConfigs.map((tenant, index) => {
                            return {
                              label: tenant.tenantName,
                              id: tenant.tenantId,
                            };
                          })
                        }
                        fullWidth={true}
                        multiple
                        onChange={(event, value) => {
                          selectedGroup.tenantIds = value.map((v) => v.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant={'standard'}
                            label={t('search_tenant', 'Search tenant...')}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {!!busySub && (
            <div className='p-3'>
              <TailSpin
                wrapperStyle={{ display: 'inline-block' }}
                color='#385690'
                height={32}
                width={32}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={() => setDeleteConfirmOpen(true)}>
            {t('DELETE', 'Slett')}
          </Button>
          <Button variant='text' onClick={() => setOpen(false)}>
            {t('CANCEL', 'Avbryt')}
          </Button>
          <Button variant='contained' onClick={saveGroup} color='primary'>
            {t('SAVE', 'Lagre')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title='Are you sure you want to delete this report group?'
        open={deleteConfirmOpen}
        setOpen={setDeleteConfirmOpen}
        onConfirm={deleteGroup}></ConfirmDialog>
    </>
  );
};
