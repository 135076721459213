import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import { InputType } from '../input-type.enum'
import { Input, InputLabel } from '@mui/material'

export type CubitNumericFieldAdapterProps = { type: InputType.NumericField } // type props are required to differenciate between different field adapters

const CubitNumericFieldAdapter: React.FC<CubitNumericFieldAdapterProps> = (props: any) => {
    const {
        className,
        input: { name, value, onChange, onBlur, onFocus },
        options,
        formcontrolprops,
        label,
        meta: { touched, error },
        ...rest
    } = props

    return (
        <FormControl variant="standard" {...formcontrolprops}>
            <FormGroup row>
                {label ? <InputLabel>{label}</InputLabel> : null}
                <Input
                    type="number"
                    className={className}
                    error={touched && error ? true : false}
                    helperText={touched && error}
                    {...rest}
                    name={name}
                    onChange={(event: any) => onChange(event.target.value)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    style={{ width: '100%' }}
                />
            </FormGroup>
        </FormControl>
    );
}

export { CubitNumericFieldAdapter }
