import { Box, Tab, Tabs, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RevolvingDot, TailSpin } from 'react-loader-spinner';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { TopBar } from '../components/top-bar';
import {
  ReportBaseQuery,
  ReportColumn,
  ReportDefinition,
  ReportFilter,
  ReportGroup,
  ReportResultDefinition,
  ReportResultTypeEnum,
} from '../models/report-details';
import { BaseQueryColumnInfo } from '../models/report-info';
import { generateUUID } from '../utils/common-utils';
import { TabContext } from '@mui/lab';
import { SaveReport } from '../models/save-report';
import ReportConfigClient from '../clients/ReportConfigClient';
import { ReportColumnsTab } from '../components/config-view/columns-tab';
import { ReportFiltersTab } from '../components/config-view/filters-tab';
import ConfirmDialog from '../components/confirm-dialog';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../redux/app-store';
import { ReportsActions } from '../redux/reports-actions';
import { TopBarUser } from '../components/top-bar-user';
import { LanguagePicker } from '../components/language-picker';
import { LogoutButton } from '../components/logout-button';
import { ReportButton } from '../components/dashboard/report-button';
import DeleteIcon from '@mui/icons-material/Delete';
import { HistoryDrawer } from '../components/history/history-drawer';
import InfoIcon from '@mui/icons-material/Info';
import { TopBarReturn } from '../components/top-bar-return';
import { EditReport } from '../components/config-view/edit-report';

const useStyles = makeStyles((theme: Theme) => ({
  reportGroupSelector: {
    margin: '16px',
    width: '80%',
  },
  paper: {
    paddingBottom: '8px',
  },
  formControl: {
    margin: '8px 16px 16px 16px',
    width: '80%',
  },
  button: {
    textTransform: 'initial',
  },
  loader: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    margin: '16px 0',
  },
  unsavedChanges: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
    width: '66%',
    backgroundColor: '#fff',
  },
  addIcon: {
    marginTop: '36px',
    width: '32px',
    height: '32px',
    color: '#44ef50',
    cursor: 'pointer',
  },
  editIcon: {
    marginTop: '38px',
    width: '26px',
    height: '26px',
    color: '#d09440',
    cursor: 'pointer',
    border: '1px solid #d09440',
    borderRadius: '4px',
  },
  history: {
    height: '100%',
  },
  historyBox: {
    overflowY: 'auto',
    maxHeight: '60vh',
    borderBottom: '1px solid #ccc',
  },
  infoIcon: {
    marginRight: '12px',
  },
  reportActionsContainer: {
    display: 'flex',
  },
}));

type ReportsPageProps = RouteComponentProps<{ reportDefinitionCode?: string }>;
const ConfigPage: React.FC<ReportsPageProps> = (props: ReportsPageProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const reportList = useSelector(
    (state: AppState) => state.reports.reportGroupList
  );

  const [baseQueries, setBaseQueries] = useState<ReportBaseQuery[]>(null);
  const [selectedGroup, setSelectedGroup] = useState<ReportGroup>(null);
  const [selectedReport, setSelectedReport] = useState<ReportDefinition>(null);
  const [selectedGroupSub, setSelectedGroupSub] = useState<Subscription>(null);
  const [selectedColumn, setSelectedColumn] = useState<ReportColumn>(null);
  const [selectedBaseQuery, setSelectedBaseQuery] =
    useState<ReportBaseQuery>(null);
  const [selectedFilter, setSelectedFilter] = useState<ReportFilter>(null);
  const [potentialColumns, setPotentialColumns] = useState<
    BaseQueryColumnInfo[]
  >([]);
  const [newColumns, setNewColumns] = useState<ReportColumn[]>(null);
  const [newFilters, setNewFilters] = useState<ReportFilter[]>(null);
  const [selectedCommonTab, setSelectedCommonTab] =
    useState<string>('viewmodel-columns');
  const [savingDeletingSub, setSavingDeletingSub] =
    useState<Subscription>(null);
  const [newReport, setNewReport] = useState<ReportDefinition>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const reportDefinitionCode = props.match
    ? props.match.params.reportDefinitionCode
    : null;

  useEffect(() => {
    ReportConfigClient.getAllBaseQueries().subscribe((x) => setBaseQueries(x));
  }, []);

  useEffect(() => {
    if (reportDefinitionCode && reportList) {
      var reportInfo;
      var group = reportList.find(
        (x) =>
          (reportInfo = x.reports.find((y) => y.code === reportDefinitionCode))
      );
      if (!!group && !!reportInfo) {
        selectGroup(group.code, reportInfo.code);
      }
    }
  }, [reportList]);

  useEffect(() => {
    if (!baseQueries) {
      return;
    }
    if (!!selectedReport) {
      selectedReport.resultDefinitions.forEach((result) => {
        result.columnDefinitions = result.columnDefinitions.sort((a, b) =>
          a.columnOrder >= b.columnOrder ? 1 : -1
        );
        result.columnDefinitions.forEach((col, index) => {
          col.uuid = generateUUID();
        });
      });

      selectedReport.filterDefinitions.forEach((filter, index) => {
        filter.uuid = generateUUID();
        const params = JSON.parse(filter.jsonParams);
        if (!!params) {
          filter.clusionType = params.clusionType as string;
          filter.clusionItems = params.clusionItems || [];
          filter.defaultValueMin = params.defaultValueMin;
          filter.defaultValueMax = params.defaultValueMax;
          filter.isHidden = params.isHidden === 'true';
        } else {
          filter.clusionType = 'all';
          filter.clusionItems = [];
        }
      });
    }
    if (selectedReport) {
      if (
        selectedBaseQuery?.code !== selectedReport.baseQueryCode &&
        !!selectedReport.baseQueryCode
      ) {
        selectBaseQueryByCode(selectedReport.baseQueryCode);
      } else if (!selectedReport.baseQueryCode) {
        const reportInfo = reportList
          .find((x) => x.code === selectedGroup.code)
          .reports.find((x) => x.code === selectedReport.code);
        if (!!reportInfo) {
          selectBaseQueryByCode(reportInfo.baseQueryCode);
        } else {
          selectBaseQuery(null);
        }
      }
    }
    setSelectedColumn(null);
    setSelectedFilter(null);
  }, [selectedReport, baseQueries]);

  useEffect(() => {
    if (!!newReport) {
      setSelectedReport(newReport);
    }
  }, [newReport]);

  const selectGroup = (
    groupCode: string,
    postLoadReportCodeSelect?: string
  ) => {
    setSelectedReport(null);
    setSelectedColumn(null);
    setPotentialColumns([]);
    const sub = ReportConfigClient.getReportGroupDetails(groupCode).subscribe(
      (x) => {
        setSelectedGroup(x);
        setSelectedGroupSub(null);
        if (!!postLoadReportCodeSelect) {
          const report = x.reports.find(
            (x) => x.code === postLoadReportCodeSelect
          );
          setSelectedReport(report);
        }
      }
    );
    setSelectedGroupSub(sub);
  };

  const selectBaseQueryByCode = (baseQueryCode: string) => {
    var baseQuery = baseQueries.find((x) => x.code === baseQueryCode);
    if (!!baseQuery) {
      selectBaseQuery(baseQuery);
    }
  };

  const selectBaseQuery = (baseQuery: ReportBaseQuery) => {
    selectedReport.baseQueryCode = baseQuery?.code;
    setSelectedBaseQuery(baseQuery);
    setSelectedColumn(null);
    setPotentialColumns([]);
    if (!!baseQuery) {
      ReportConfigClient.getReportPotentialColumns(baseQuery.code).subscribe(
        (x) => {
          setPotentialColumns(x);
        }
      );
    }
  };

  const saveReport = () => {
    const report: SaveReport = {
      ...selectedReport,
      groupCode: selectedGroup.code,
    };
    report.resultDefinitions.forEach((result) => {
      result.columnDefinitions.forEach((col, index) => {
        col.columnOrder = index;
      });
    });
    const updatedGroup = JSON.parse(JSON.stringify(selectedGroup));

    updatedGroup.reports = [...(selectedGroup.reports || [])];
    const sub = ReportConfigClient.saveReport(report).subscribe((x) => {
      x.baseQueryCode = selectedReport.baseQueryCode;
      var reportIndex = updatedGroup.reports.findIndex(
        (r) => r.code === x.code
      );
      if (reportIndex !== -1) {
        updatedGroup.reports[reportIndex] = x;
      } else {
        updatedGroup.reports.push(x);
        setNewReport(null);
      }
      setSelectedReport(x);
      setSavingDeletingSub(null);
      ReportsActions.actionLoadReportGroups()(dispatch);
    });
    setSavingDeletingSub(sub);
  };
  const deleteReport = () => {
    if (selectedReport === newReport) {
      setNewReport(null);
      setSelectedReport(null);
    } else {
      setSavingDeletingSub(
        ReportConfigClient.deleteReport(selectedReport.code).subscribe(() => {
          var reportIndex = selectedGroup.reports.findIndex(
            (r) => r.code === selectedReport.code
          );
          if (reportIndex !== -1) {
            selectedGroup.reports.splice(reportIndex, 1);
          }
          setSelectedReport(null);
          setSavingDeletingSub(null);
          ReportsActions.actionLoadReportGroups()(dispatch);
          history.push(`/dashboard/`);
        })
      );
    }
  };
  const solidifyReportResult = (result: ReportResultDefinition) => {
    result.isNew = false;
    selectedReport.resultDefinitions.push(result);
  };

  const setHasUnsavedChanges = (hasUnsavedChanges: boolean) => {
    if (!!selectedReport) {
      if (selectedReport.hasUnsavedChanges !== hasUnsavedChanges) {
        selectedReport.hasUnsavedChanges = hasUnsavedChanges;
      }
    }
  };

  const renderCommonTabs = () => {
    const viewModelResult = selectedReport.resultDefinitions.find(
      (x) => x.type === ReportResultTypeEnum.ViewModel
    ) || {
      isNew: true,
      type: ReportResultTypeEnum.ViewModel,
      columnDefinitions: [],
    };
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            padding: '0 16px ',
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Tabs
            sx={{ backgroundColor: '#fff', paddingBottom: '16px' }}
            value={selectedCommonTab}
            onChange={(ev, val) => setSelectedCommonTab(val)}>
            <Tab
              label={t('COLUMNS', 'Kolonner')}
              value='viewmodel-columns'></Tab>
            <Tab label={t('FILTERS', 'FILTER')} value='filters'></Tab>
          </Tabs>
          <div className={styles.reportActionsContainer}>
            {!savingDeletingSub && (
              <>
                <ReportButton
                  onClick={() => setDeleteConfirmOpen(true)}
                  primary
                  startIcon={<DeleteIcon />}
                  title={t('DELETE_REPORT', 'SLETT RAPPORT')}
                />
                <div style={{ marginLeft: '16px' }}>
                  <ReportButton
                    onClick={() => saveReport()}
                    primary
                    title={t('SAVE_REPORT', 'LAGRE REPORT')}
                  />
                </div>
              </>
            )}
          </div>
        </Box>
        {selectedReport.hasUnsavedChanges && (
          <div className={styles.unsavedChanges}>
            <div className={styles.infoIcon}>
              <InfoIcon sx={{ color: '#ffb74d' }} />
            </div>
            <div>
              {t(
                'DRAFT_MESSAGE',
                'Du har gjort endringer i rapporten. Arbeidet ditt lagres som et utkast og trer ikke i kraft før du lagrer.'
              )}
            </div>
          </div>
        )}
        <TabContext value={selectedCommonTab}>
          <ReportColumnsTab
            tabName='viewmodel-columns'
            setSelectedColumn={setSelectedColumn}
            selectedColumn={selectedColumn}
            setNewColumns={setNewColumns}
            newColumns={newColumns}
            setSelectedReport={setSelectedReport}
            selectedReport={selectedReport}
            reportResult={viewModelResult}
            potentialColumns={potentialColumns}
            setHasUnsavedChanges={setHasUnsavedChanges}
            solidifyNewResult={solidifyReportResult}></ReportColumnsTab>
          <ReportFiltersTab
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            setNewFilters={setNewFilters}
            newFilters={newFilters}
            selectedReport={selectedReport}
            potentialColumns={potentialColumns}
            setHasUnsavedChanges={setHasUnsavedChanges}></ReportFiltersTab>
        </TabContext>
      </>
    );
  };

  return (
    <Fragment>
      <TopBar
        pageTitle={t('CONFIG')}
        leftElements={[
          <TopBarReturn
            key={'topbar-return'}
            report={selectedReport && selectedReport.code}
            group={selectedGroup && selectedGroup.code}
          />,
          <div key={'config'}>
            <span>{`${t('CONFIG', 'Konfigurasjon')}: `}</span>
            <span></span>
            <b>{selectedReport && selectedReport.name}</b>
          </div>,
          <EditReport
            key={'edit-report'}
            group={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            report={selectedReport}
            setSelectedReport={setSelectedReport}
            setHasUnsavedChanges={setHasUnsavedChanges}
            baseQuery={selectedBaseQuery}
          />,
        ]}
        rightElements={[
          <TopBarUser key={'top-bar-user'} />,
          <LanguagePicker key='language-picker' />,
          <LogoutButton key='logout-button' />,
        ]}></TopBar>
      <div className='pageContainer'>
        {!!selectedReport && (
          <div>
            <div>
              <div className={styles.paper}>
                {(potentialColumns?.length > 0 ||
                  selectedReport === newReport) && <>{renderCommonTabs()}</>}
                {!potentialColumns?.length && selectedReport !== newReport && (
                  <div className={`${styles.loader} pt-3`}>
                    <RevolvingDot
                      wrapperStyle={{ display: 'inline-block' }}
                      color='#385690'
                      height={100}
                      width={100}
                    />
                  </div>
                )}
                {savingDeletingSub && (
                  <div className='p-3'>
                    <TailSpin
                      wrapperStyle={{ display: 'inline-block' }}
                      color='#385690'
                      height={32}
                      width={32}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              {selectedReport.historyEvents.length > 0 && (
                <HistoryDrawer
                  setNewReport={setNewReport}
                  setSelectedReport={setSelectedReport}
                  setSavingDeletingSub={setSavingDeletingSub}
                  events={selectedReport?.historyEvents}
                  selectedGroup={selectedReport?.historyEvents}
                />
              )}
            </div>
          </div>
        )}
        {!!selectedGroupSub && (
          <div className={styles.loader}>
            <RevolvingDot
              wrapperStyle={{ display: 'inline-block' }}
              color='#385690'
              height={100}
              width={100}
            />
          </div>
        )}
        <ConfirmDialog
          title={t(
            'REPORT_DELETE_CONFIRMATION',
            'Du er i ferd med å slette denne rapporten. Da slettes også alle kolonner og filter som ligger i denne rapporten.'
          )}
          confirmationText={t('deleteReport', 'SLETT RAPPORT')}
          open={deleteConfirmOpen}
          setOpen={setDeleteConfirmOpen}
          onConfirm={deleteReport}></ConfirmDialog>
      </div>
    </Fragment>
  );
};

export default withRouter(ConfigPage);
